import Slider from "react-slick";

import { Link } from "react-router-dom";

import theme from '../styles/theme.module.scss'
import styles from '../styles/imageTiles.module.scss'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageTiles = (props) => {
    
    const variantClass = props.variant;

    const slideConfig = {
        arrows: true,
        infinite: false,
        dots: true,
        variableWidth: false,
        duration: 750,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    arrows: false,
                    infinite: false,
                    dots: true,
                    variableWidth: true,
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    infinite: false,
                    dots: true,
                    variableWidth: true,
                    slidesToShow: 2,
                    slidesToScroll: 2
                },
            }
        ]
    };

    const imageThumbnails = Object.values(props.context).map((item, order) => {
        const data = item;

        const newIcon = <img className={styles.ll_flag_icon} alt="newly added" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_r3t1rLshtg2gAA1TveswXRminz-Y-tbR4g&usqp=CAU" />;

        return (
            <Link 
                to={`${data.unitCode}/${data.code}`}
                className={`${styles.ll_image_item} ${data.status === 'inprogress' ? styles.ll_active_card : ''} ${ data.active ? '' : styles.disabled }`}
                key={order+data.unitCode}
            >
                <div className={styles.ll_image_wrap}>
                    { data.flag === "new" ? newIcon : '' }
                    <div className={styles.ll_image_icon_wrap}>
                        <img className={styles.ll_image_item_icon} src={data.img} alt={data.title}></img>
                    </div>
                    <div className={styles.ll_image_title_wrap}>
                        <span className={styles.ll_image_item_title}>{data.displayName}</span>
                    </div>
                </div>
                <div className={styles.ll_status_indicator}></div>
                {/* <img className={styles.ll_progress_icon} src={`images/icons/${item.status}.png`} alt="Completed"></img> */}
            </Link>
        )
    });

    return (
        <section className={`${theme.ll_section} ${styles.ll_image_tiles} ${variantClass} ll_image_tiles ll_slick_dots`}>
            <div className={`${theme.ll_container} ${styles.ll_carousel_container}`}>
                <div className="ll_content_wrap">
                    <div className={styles.ll_wrapper}>
                        <div className={styles.ll_title_wrap}>
                            <span className={styles.ll_unit_no}>{props.order}</span>
                            <h3 className={styles.ll_h3}>{props.title}</h3>
                        </div>
                    </div>
                    <Slider {...slideConfig} className={styles.ll_icons_slider}>
                        {imageThumbnails}
                    </Slider>
                </div>
            </div>
        </section>
    );
}

export default ImageTiles;