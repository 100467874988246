import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import PageLayout from "../layouts/pageLayout";
import { baseURL } from "../App";
import endpoints from '../data/api.json'
import Loader from "../components/loader";
import Breadcrumbs from "../components/breadcrumbs";
import generatePayload from "../helpers/generatePayload";
import { CoreContext } from "../config/context";
import FilterableTabs from "../components/filterableTabs";
import EventCards from "../components/eventCards";

const Rewards = () => {
    const globals = useContext(CoreContext);

    const [ compContext, setContext ] = useState({});
    const [ loading, setLoading ] = useState(false);

    const payload = {
        uid: localStorage.getItem('JWUid'),
        sid: localStorage.getItem('JWToken')
    };

    const crumbs = [
        { name: 'முகப்பு', path: '/' },
        { name: 'வெகுமதிகள்', path : `/rewards` }
    ];

    const rewardsData = {
        filters: [
            { key: 'trending', label: 'பிரபலமானவை' },
            { key: 'product', label: 'பொருட்கள்' },
            { key: 'coupon', label: 'கூப்பன்கள்' },
            { key: 'wishlist', label: 'விருப்பப்பட்டியல்' },
            { key: 'others', label: 'இதர வெகுமதிகள்' }
        ],
        data: {
            pro123: {
                name: 'போல்ட் ஸ்மார்ட் வாட்ச் 2023',
                image: 'https://staticimg.titan.co.in/Titan/Catalog/90149AP01_1.jpg',
                desc: 'Fire Bolt Smart Watch',
                value: '2500 pts',
                rating: 4.5,
                filter: 'trending,product,wishlist'
            },
            pro456: {
                name: 'போட் வயர்லஸ் இயர்போன்கள்',
                image: 'https://cdn.shopify.com/s/files/1/0057/8938/4802/products/rockerz-185-red.png',
                desc: 'BoAT Earphones - Wireless',
                value: '1500 pts',
                rating: 4.2,
                filter: 'trending,product'
            },
            pro789: {
                name: 'ஜியோ 5ஜி இன்டர்நெட் டான்கிள்',
                image: 'https://5.imimg.com/data5/SELLER/Default/2022/1/DJ/YD/DX/141757994/jio-dongle-500x500.jpg',
                desc: 'High Speed Internet Dongle from Jio',
                value: '750 pts',
                rating: 4.2,
                filter: 'trending,product'
            },
            cpn789: {
                name: 'ஜியோ 249 ரீசார்ஜ் கூப்பன்',
                image: 'https://i2.wp.com/www.allrechargeplans.com/wp-content/uploads/2017/11/jio-recharge-offers-tag-1481715791.jpg',
                desc: 'Jio Recharge Coupon for Rs.249',
                value: '300 pts',
                rating: 3.5,
                filter: 'trending,coupon'
            },
            prod101: {
                name: 'ஏர்டெல் 5ஜி இன்டர்நெட் டான்கிள்',
                image: 'https://img.poorvika.com/cdn-cgi/image/width=450,height=450,quality=75/1600_JPG/Mobile%20Accesories/Airtel/Airtel-4G-My-WiFi-Data-Card-Black/Airtel-4G-My-WiFi-Data-Card-Black.jpg',
                desc: 'High Speed Internet Dongle from AirTel',
                value: '750 pts',
                rating: 3.6,
                filter: 'product'
            },
            prod102: {
                name: 'மேபேட் ஜாமெட்ரிக் பாக்ஸ்',
                image: 'https://ii1.pepperfry.com/media/catalog/product/m/a/800x880/Maped-GEOMETRY-BOX---9-PCS-Maped0189-1369320994Wr1D92.jpg',
                desc: 'Maped Precision Geometric Box>',
                value: '500 pts',
                rating: 4,
                filter: 'product,trending,wishlist'
            },
            prod103: {
                name: 'க்ளாஸ்மேட் 180 பக்க நோட்டுகள்',
                image: 'https://5.imimg.com/data5/XO/DH/MY-42986030/writing-note-book-500x500.jpg',
                desc: 'Classmate 180 pages notebooks',
                value: '200 pts',
                rating: 3,
                filter: 'product,trending,wishlist'
            },
            cpn7891: {
                name: 'ஏர்டெல் 149 ரீசார்ஜ் கூப்பன்',
                image: 'https://assets.airtel.in/teams/simplycms/web/images/prepaid-offer-coupon-3-new-22022022.png',
                desc: 'AirTel Recharge Coupon for Rs.249',
                value: '175 pts',
                rating: 3.5,
                filter: 'trending,coupon,wishlist'
            },
            cpn7892: {
                name: 'வீஐ 333 ரீசார்ஜ் கூப்பன்',
                image: 'https://tamil.samayam.com/photo/msid-89668805,imgsize-45644/pic.jpg',
                desc: 'VI Recharge Coupon for Rs.333',
                value: '450 pts',
                rating: 3.5,
                filter: 'trending,coupon'
            },
            prod104: {
                name: 'அக்னி சிறகுகள் புத்தகம்',
                image: 'https://www.madrasshoppe.com/25608-large_default/my-journey-apj-apj-abdul-kalam.jpg',
                desc: 'Book by Former president APJ',
                value: '200 pts',
                rating: 3,
                filter: 'others'
            }
        }
    };
    

    const fetchData = () => {

        const payload = {
            uid: localStorage.getItem('JWUid'),
            sid: localStorage.getItem('JWToken')
        };

        axios.post(`${baseURL}${endpoints.fetchLibrary}`, generatePayload(payload))
            .then(response => {
                const token = response.data.token;

                if (!token) {
                    window.location.href = '/login';
                } else {
                    setContext(response.data);

                    globals.CoreDispatch({
                        udata: response.data.udata,
                        theme: globals.CoreState['theme']
                    });

                    setLoading(true);
                }

                localStorage.setItem("JWToken", token);

                setAuthToken(token);

                document.title = `தமிழ் - 🏆 வெகுமதிகள்`;
            })
            .catch(error => {
                console.log(error)
                setAuthToken(false);
            });
    };

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <PageLayout>
            { loading?
                (
                    <>
                        <Breadcrumbs context={crumbs} />
                        <FilterableTabs
                            title="வெகுமதிகள்"
                            filters={rewardsData.filters}
                            defaultFilter="trending"
                            context={Object.values(rewardsData.data)}
                            child='ProductCards'/>
                    </>
                )
                :
                (
                    <Loader></Loader>
                )
            }
        </PageLayout>
    );
}

export default Rewards;