import React, { useEffect, useRef, useState } from "react";
import styles from '../styles/login.module.scss'
import themes from '../styles/theme.module.scss'
import Slider from "react-slick";
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import BrandLogo from '../static/ll_tamizh_logo.svg'
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../App";
import endpoints from '../data/api.json';
import generatePayload from "../helpers/generatePayload";
import { setAuthToken } from "../helpers/authToken";

import { ReactTransliterate } from "react-transliterate";
import ButtonPrimary from '../components/partials/buttonPrimary'
import '../styles/react-transliteration.css';

const Register = () => {
    const mirrorTamilName = useRef<HTMLInputElement>(null);

    const [ context, setContext ] = useState({});

    const [ view, setView ] = useState('formView');
    const [ message, setMessage ] = useState('');

    const [ buttonState, updateButtonState ] = useState('default');
    // const [ userName, setUserName ] = useState('');
    const [ tamilName, setTamilName ] = useState('');
    const [ userEmail, setUserEmail ] = useState('');
    const [ userClass, setUserClass ] = useState(false);
    const [ userGroup, setUserGroup ] = useState(false);
    const [ userType, setUserType ] = useState(false);
    const [ userGender, setUserGender ] = useState(false);
    const [ userConsent, setUserConsent ] = useState(false);

    useEffect(() => {
        console.log(userConsent);
    }, [ userConsent ]);

    // const { control, register, formState: { errors }, handleSubmit } = useForm({
    //     mode: 'onBlur',
    //     reValidateMode: 'onChange'
    // });

    // const formValid = userType && userGender && tamilName && userEmail && userClass && userGroup;

    const standard = [
        { value: '9', label: '9th' },
        { value: '10', label: '10th' },
        { value: '11', label: '11th' },
        { value: '12', label: '12th' }
    ];
    
    const group = [
        { value: 'G103', label: 'உயிரியல்- இயற்பியல் - வேதியியல் - கணிதம்' },
        { value: 'G102', label: 'கணினி அறிவியல் - இயற்பியல் - வேதியியல் - கணிதம்' },
        { value: 'G208', label: 'தாவரவியல் - விலங்கியல் - இயற்பியல் - வேதியியல்' }
    ];      

    const customStyles = {
        valueContainer: (provided, state) => ({
            ...provided,
            padding: '0 4px 0 16px',
            marginTop: '-2px'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            fontSize: '12px',
            fontWeight: '500',
        }),
        menuList: (provided, state) => ({
            ...provided,
            fontSize: '14px',
            fontWeight: '500',
            zIndex: '20'
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: '20'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            fontWeight: '600',
            color: '#111827',
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            backgroundColor: state.isSelected ? '#6F42C1' : ( state.isFocused ? '#ebe0ff' : '' )
        }),
        input: (provided, state) => ({
            ...provided,
            fontSize: '10px',
            fontWeight: '100',
            outline: 'none'

        }),
        control: (provided, state) => ({
            ...provided,
            boxShadow: 'none',
            outline: 'none',
            borderColor: state.isSelected ? '#6F42C1' : ( state.isFocused ? '#6F42C1' : '' ),
            minHeight: '30px',
            maxHeight: '36px'
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#6F42C1' : ( state.isFocused ? '#6F42C1' : '#CCCCCC' ),
        })
    };

    // const mobile = search.get('user') ? search.get('user') : false;

    const fetchData = (email, pass) => {

        // axios.get('data/home.json')
        //     .then(response => {
        //         const token = response.data.token;

        //         if (!token || !mobile) {
        //             window.location.href = '/login';
        //         } else {
        //             setContext(response.data);
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error)
        //         setAuthToken(false);
        //     });
    };

    useEffect(() => fetchData(), []);

    const hookLines = [
        { "title": "", "caption": "" , "img": "images/slides/slide-1.jpg"},
        { "title": "", "caption": "" , "img": "images/slides/slide-2.jpeg"},
        { "title": "", "caption": "" , "img": "images/slides/slide-3.jpeg"},
        { "title": "", "caption": "", "img": "images/slides/slide-4.jpg" }
    ];

    const slides = hookLines.map((slide) => {
        return (
            <div className={styles.ll_login_slide}>
                <img className={styles.ll_login_bg_img} src={slide.img} alt={styles.title}></img>
                <div className={styles.ll_login_slide_wrap}>
                    <h4 className={styles.ll_login_slide_title_wrap}>
                        <span className={styles.ll_logon_title_span}>
                            <span className={styles.ll_logon_title_innerspan_1}>{slide.title}</span>
                            <span className={styles.ll_logon_title_innerspan_2}>{slide.title}</span>
                        </span>
                    </h4>
                    <p className={styles.ll_login_slide_caption}>{slide.caption}</p>
                </div>
            </div>
        );
    });

    const slideConfig = {
        arrows: false,
        infinite: true,
        mobileFirst: true,
        autoplay: true,
        fade: true,
        speed: 1000,
        autoplaySpeed: 5000,
        duration: 250,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1023,
                settings: "unslick"
            }
        ]
    };

    // const updateUserName = (e) => setUserName(e.target.value);

    const updateTamilName = (value) => setTamilName(value);

    const updateUserEmail = (e) => {
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        setUserEmail(e.target.value);

        console.log(pattern.test(e.target.value));
    }

    const userStandardChangeHandler = (option) => {
        setUserClass(option.value);
        console.log(option);
    };

    const userGroupChangeHandler = (option) => setUserGroup(option.value);

    const userTypeRadioHandler = (e) => {
        setUserType(e.target.value);

        console.log(e.target.value);
    }

    const userGenderRadioHandler = (e) => {
        setUserGender(e.target.value);

        console.log(e.target.value);
    }

    const userConsentChangehandler = () => {
        setUserConsent(!userConsent);
    }

    const registerUser = ( event ) => {
        event.preventDefault();

        updateButtonState('loading');

        console.log('Button clicked!',event);

        const registerPayload = {
            // firstNameEnglish: userName,
            name: tamilName,
            uid: localStorage.getItem("JWUid"),
            sid: localStorage.getItem("JWToken"),
            gender: userGender,
            std: userClass,
            email: userEmail,
            userType: userType,
            group: userGroup
        };

        console.log(registerPayload);

        axios.post(`${baseURL}${endpoints.registerUser}`, generatePayload(registerPayload))
            .then(response => {
                setMessage(response.data.msg);

                if (response.data.status) {
                    if (response.data.regd) {
                        const responseData = response.data;
                        const token = responseData.token;

                        localStorage.setItem("JWToken", token);
                        setAuthToken(token);

                        updateButtonState('success');

                        setTimeout(() => {
                            window.location.href = `/${responseData.redirect}`;
                        }, 5000);
                    } else {

                        setTimeout(() => {
                            window.location.href = `/login`;
                        }, 5000);
                    }
                }
                else {
                    // setOtpError({ status: true, msg: response.data.msg });
                    updateButtonState('error');
                }
            })
            .catch(error => console.log(error));
    };
    
    const registerSubmit = data => console.log(data); 

    const handleTamilType = (val) => {
        updateTamilName(val);

        console.log(mirrorTamilName);
    };

    return (
        <section className={styles.ll_login}>
            <div className={styles.ll_login_bg}></div>
            <div className={themes.ll_container}>
                <div className={styles.ll_login_wrap}>
                    <div className={styles.ll_login_bgwrap}>
                        <img className={ styles.ll_regn_bg_img }
                            // src="https://i.pinimg.com/originals/ce/c6/0b/cec60b45d8a44a2ca7bc047450ade16d.png"
                            src="images/slides/illustration-slide-4.jpg"
                            alt="some">
                        </img>
                    </div>
                    <div className={styles.ll_login_form}>
                        <div className={styles.ll_login_brand}>
                            <img src={BrandLogo} alt="some"></img>
                        </div>
                        <div className={styles.ll_login_titlewrap}>
                            <h3 className={styles.ll_login_title}>புதிய பயனராக பதிவு செய்ய</h3>
                            <span className={styles.ll_login_caption}></span>
                        </div>
                        {
                            ( view === 'formView' )
                            ?
                            <form className={`${styles.ll_register_title}`}>
                                <div className={styles.ll_login_formgroup}>
                                    {/* <label className={styles.ll_form_basic_label}>பெயர்</label> */}
                                    <ReactTransliterate 
                                        className={` ${styles.ll_forminput} ${styles.register_input} ${styles.ll_tamilinput}`}
                                        placeholder="தமிழ் உரையில் பெயர்"
                                        onChangeText={ handleTamilType }
                                        value={ tamilName }
                                        lang="ta"
                                    />
                                    {/* <Controller
                                        render={({ field }) =>
                                            <ReactTransliterate 
                                                {...field} 
                                                className={` ${styles.ll_forminput} ${styles.register_input} ${styles.ll_tamilinput}`}
                                                placeholder="தமிழ் உரையில் பெயர்"
                                                onChangeText={ handleTamilType }
                                                lang="ta"
                                            />
                                        }
                                        name="tamilName"
                                        control={control}
                                        rules={{ required: true }}
                                    /> */}
                                    {/* <input
                                        className={`opacity-0 w-0 h-0`}
                                        value={ tamilName }
                                        ref={mirrorTamilName}
                                        type="text"
                                        name="mirrorTamilName"
                                        {...register ("userName", { required: true })}
                                    /> */}
                                    {/* <p className="font-medium text-red-500 text-base text-left">
                                        {errors.userName?.type === 'required' && "User name is required"}
                                    </p> */}
                                </div>
                                <div className={styles.ll_login_formgroup}>
                                    {/* <label className={styles.ll_form_basic_label}>மின்னஞ்சல் முகவரி</label> */}
                                    <input
                                        className={` ${styles.ll_forminput} ${styles.register_input}`}
                                        placeholder="மின்னஞ்சல் முகவரி"
                                        type='email'
                                        id="otp"
                                        name="userEmail"
                                        onChange={ updateUserEmail }
                                        // {...register ("userEmail", {
                                        //     required: "Email ID is required.",
                                        //     pattern: {
                                        //         value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        //         message: "Please enter a valid email."
                                        //     }
                                        // })}
                                    />
                                    <p className="font-medium text-red-500 text-base text-left">
                                        {/* {errors.userEmail?.type === 'required' && "Please enter an email ID"} */}
                                    </p>
                                    <p className="font-medium text-red-500 text-base text-left">
                                        {/* {errors.userEmail?.message === 'required' && "Please enter a valid email ID"} */}
                                    </p>
                                </div>
                                <div className={`${styles.ll_login_formgroup} ${styles.ll_login_formgroup_double}`}>
                                    <div className={ `${styles.selectWrapper} ${styles.selectWrapper_sm}` }>
                                        {/* <label className={styles.ll_form_basic_label}>வகுப்பு</label> */}
                                        <Select 
                                            isSearchable={false} 
                                            options={ standard } 
                                            styles={ customStyles } 
                                            placeholder="வகுப்பு"
                                            onChange={ userStandardChangeHandler }>
                                        </Select>
                                    </div>
                                    {/* <input className={` ${styles.ll_forminput}`} placeholder="Grade" type='tel' id="otp" name="otp"/> */}
                                    <div className={ `${styles.selectWrapper} ${styles.selectWrapper_lg}`  }>
                                        {/* <label className={styles.ll_form_basic_label}>பிரிவு</label> */}
                                        <Select 
                                            isSearchable={false} 
                                            options={ group } 
                                            styles={customStyles} 
                                            placeholder="பிரிவு"
                                            onChange={ userGroupChangeHandler }>
                                        </Select>
                                    </div>
                                </div>
                                <div className={`${styles.ll_login_formgroup} ${styles.ll_login_formgroup_double} ${styles.ll_login_formgroup_btnGrp}`}>
                                    <label className={styles.ll_form_basic_label}>பயனர் வகை</label>
                                    <div className={styles.ll_radiogroup}>
                                        <div className={styles.ll_radioitem}>
                                            <label className={ styles.ll_radio_label }>
                                                <input class={styles.ll_radio_btn}
                                                    // {...register("userType", { required: "User Type is required" })}
                                                    type="radio"
                                                    value="student"
                                                    checked={ userType === 'student' }
                                                    onChange={ userTypeRadioHandler }
                                                />
                                                <span>மாணவர்</span>
                                            </label>
                                        </div>
                                        <div className={styles.ll_radioitem}>
                                            <label className={ styles.ll_radio_label }>
                                                <input class={styles.ll_radio_btn}
                                                    // {...register("userType", { required: "User Type is required" })}
                                                    type="radio"
                                                    value="teacher"
                                                    checked={ userType === 'teacher' }
                                                    onChange={ userTypeRadioHandler }
                                                />
                                                <span>ஆசிரியர்</span>
                                            </label>
                                        </div>
                                    </div>
                                    {/* <p className="font-medium text-red-500 text-base text-left">
                                        {errors.userType?.type === 'required' && "User type is required"}
                                    </p> */}
                                </div>
                                <div className={`${styles.ll_login_formgroup} ${styles.ll_login_formgroup_double} ${styles.ll_login_formgroup_btnGrp}`}>
                                    <label className={styles.ll_form_basic_label}>பாலினம்</label>
                                    <div className={styles.ll_radiogroup}>
                                        <div className={styles.ll_radioitem}>
                                            <label className={ styles.ll_radio_label }>
                                                <input class={styles.ll_radio_btn}
                                                    // {...register("userGender", { required: "User gender is required" })}
                                                    type="radio"
                                                    value="male"
                                                    checked={ userGender === 'male' }
                                                    onChange={ userGenderRadioHandler }
                                                ></input>
                                                <span>ஆண்</span>
                                            </label>
                                        </div>
                                        <div className={styles.ll_radioitem}>
                                            <label className={ styles.ll_radio_label }>
                                                <input class={styles.ll_radio_btn}
                                                    // {...register("userGender", { required: "User gender is required" })}
                                                    type="radio"
                                                    value="female"
                                                    checked={ userGender === 'female' }
                                                    onChange={ userGenderRadioHandler }
                                                ></input>
                                                <span>பெண்</span>
                                            </label>
                                        </div>
                                    </div>
                                    {/* <p className="font-medium text-red-500 text-base text-left">
                                        {errors.userGender?.type === 'required' && "User gender is required"}
                                    </p> */}
                                </div>
                                <div className={`${styles.ll_login_formgroup} ${styles.ll_login_formgroup_inline}`}>
                                    <label className={ styles.ll_checkbox_label }>
                                        <input
                                            className={styles.ll_checkbox_input}
                                            type="checkbox"
                                            value="true"
                                            checked={ userConsent }
                                            onChange={ userConsentChangehandler }
                                        />
                                        <p className={styles.ll_checkbox_txt}>
                                            பயன்பாட்டு <Link to="/info/terms-of-use" target="_blank" >விதிமுறைகளை</Link> ஒப்புக்கொள்கிறேன்
                                        </p>
                                        <span className={ styles.ll_checkmark }></span>
                                    </label>
                                </div>
                                {/* <input
                                    className={`${styles.ll_login_btn} ${styles.btn_primary}`}
                                    type="submit"
                                    value="பதிவு செய்யவும்"
                                    // disabled={userConsent}
                                    onClick={registerUser}
                                /> */}
                                <ButtonPrimary
                                    label='பதிவு செய்யவும்'
                                    key={buttonState}
                                    state={buttonState}
                                    onClick={registerUser}
                                >
                                </ButtonPrimary>
                                <p className="text-green-900">{ message }</p>
                            </form>
                            :
                            <>
                                <p className="text-green-900">{ message }</p>
                            </>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Register;