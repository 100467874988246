import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import PageLayout from "../layouts/pageLayout";
import RollingSlides from "../components/rollingSlides";
import HeroCards from "../components/heroCards";
import VideoTiles from "../components/videoTiles";
import { baseURL } from "../App";
import endpoints from '../data/api.json'
import UpdateUserContext from "../helpers/updateUserContext";
import Loader from "../components/loader";
import Breadcrumbs from "../components/breadcrumbs";
import VideoList from "../components/videoList";
import generatePayload from "../helpers/generatePayload";
import { CoreContext } from "../config/context";

const Bookmarks = () => {
    const globals = useContext(CoreContext);

    const [ compContext, setContext ] = useState({});
    const [ loading, setLoading ] = useState(false);

    const payload = {
        uid: localStorage.getItem('JWUid'),
        sid: localStorage.getItem('JWToken'),
    };

    const crumbs = [
        { name: 'முகப்பு', path: '/' },
        { name: 'புத்தககுறிகள்', path : `/bookmarks` }
    ];

    const fetchData = () => {

        axios.post(`${baseURL}${endpoints.fetchBookmarks}`, generatePayload(payload))
            .then(response => {
                const token = response.data.token;

                if (!token) {
                    window.location.href = '/login';
                } else {
                    setContext(response.data);

                    globals.CoreDispatch({
                        udata: response.data.udata,
                        theme: globals.CoreState['theme']
                    });

                    setLoading(true);
                }

                localStorage.setItem("JWToken", token);
                localStorage.setItem("JWUid", response.data.uid);

                setAuthToken(token);

                document.title = `தமிழ் - 📌 புத்தககுறிகள்`;
            })
            .catch(error => {
                console.log(error)
                setAuthToken(false);
            });
    };

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <PageLayout>
            { loading ?
                (
                    <>
                        <Breadcrumbs context={crumbs}></Breadcrumbs>

                        {
                            compContext.usaved ?
                                <VideoList variant="default" title="புத்தககுறிகள்" context={compContext.usaved}></VideoList>
                            :
                            <p className="text-2xl font-bold text-llprimary mt-4 mb-16">
                                நீங்கள் இதுவரை எந்த வீடியோவையும் புக்மார்க் செய்யவில்லை
                            </p>
                        }
                    </>
                )
                :
                (
                    <Loader></Loader>
                )
            }
        </PageLayout>
    );
}

export default Bookmarks;