import Slider from "react-slick";

import { Link } from "react-router-dom";

import theme from '../styles/theme.module.scss'
import styles from '../styles/videoTiles.module.scss'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const VideoTiles = (props) => {
    const variantClass = props.variant;

    const slideConfig = {
        arrows: true,
        infinite: false,
        variableWidth: false,
        duration: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        mobileFirst: false,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    arrows: false,
                    infinite: false,
                    variableWidth: true,
                    duration: 750,
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    infinite: false,
                    variableWidth: true,
                    duration: 750,
                    slidesToShow: 5,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    arrows: false,
                    infinite: false,
                    variableWidth: true,
                    duration: 750,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const ctaHideClass = styles.ll_hideCta;

    const videoThumbnails = Object.values(props.context).map((item) => {
        const progressVal = {
            width: (item.progress && item.duration) ? `${Math.ceil((item.progress / item.duration) * 100)}%` : 0
        }

        return (
            <Link to={`/${item.subjectID}/${item.unitID}/${item.topicID}/${item.videoID}`} className={`${styles.ll_video_item} ll_video_item flex`} key={item.videoId}>
                <div className={styles.ll_video_wrap}>
                    <div className={styles.ll_video_thumb}>
                        <img className={styles.ll_play_icon} src={process.env.PUBLIC_URL + '/images/icons/play-icon.png'} alt="play video"></img>
                        <img className={styles.ll_video_thumb_img} src={item.videoThumbnail} alt={item.videoTitle}></img>
                        <span className={styles.ll_video_thumb_time}>{item.durationLabel}</span>
                    </div>
                    {   
                        Math.ceil(item.progress) > 0
                            ? <div className={`${styles.ll_video_progress} ll_video_progress`}>
                                <div className={styles.ll_video_progress_track}></div>
                                <div className={styles.ll_video_progress_bar} style={progressVal}></div>
                            </div>
                            : ''
                    }
                    <div className={styles.ll_video_info}>
                        <h5 className={`${styles.ll_video_title} multi-line-ellipsis`}>{item.videoTitle}</h5>
                        <p className={styles.ll_video_category}>{item.subjectName} <span className={styles.ll_video_subcategory}>• {item.topicName}</span></p>
                    </div>
                </div>
            </Link>
        );
    });

    return (
        <section className={`${theme.ll_section} ${styles.ll_video_tiles} ${variantClass} ll_video_tiles`}>
            <div className={`${theme.ll_container} ${styles.ll_carousel_container}`}>
                <div className={styles.ll_wrapper}>
                    <div className={styles.ll_title_wrap}>
                        <h3 className={styles.ll_h3}>{props.title}</h3>
                        <Link to="/library" className={`${styles.ll_h3_aside} ${props.showCta !== 'true' ? ctaHideClass : ''}`}>
                            மேலும்
                            {/* <img src="images/icons/view-more.png"></img> */}
                        </Link>
                    </div>
                </div>
                <Slider {...slideConfig} className={styles.ll_video_slider}>
                    {videoThumbnails}
                </Slider>
            </div>
        </section>
    );
}

export default VideoTiles;