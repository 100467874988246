import theme from '../styles/theme.module.scss'
import styles from '../styles/footer.module.scss'
import { Link, NavLink } from 'react-router-dom';

const Footer = (props) => {
    return (
        <footer className={styles.ll_footer}>
            <nav className={styles.ll_footer_nav}>
                <NavLink to="/" className={(navData) => navData.isActive ? `${styles.ll_footer_nav_item} ${styles.active_footer_icon}` : styles.ll_footer_nav_item }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={`${styles.ll_footer_icon} feather feather-home`}><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                    <span className="sr-only">Home</span>
                </NavLink>
                <NavLink to="/library" className={(navData) => navData.isActive ? `${styles.ll_footer_nav_item} ${styles.active_footer_icon}` : styles.ll_footer_nav_item }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={`${styles.ll_footer_icon} feather feather-youtube`}><path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path><polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon></svg>
                    <span className="sr-only">Library</span>
                </NavLink>
                <NavLink to="/bookmarks" className={(navData) => navData.isActive ? `${styles.ll_footer_nav_item} ${styles.active_footer_icon}` : styles.ll_footer_nav_item }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={`${styles.ll_footer_icon} feather feather-bookmark`}><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
                    <span className="sr-only">Bookmarks</span>
                </NavLink>
                <NavLink to="/profile" className={(navData) => navData.isActive ? `${styles.ll_footer_nav_item} ${styles.active_footer_icon}` : styles.ll_footer_nav_item }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class={`${styles.ll_footer_icon} feather feather-user`}><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                    <span className="sr-only">Profile</span>
                </NavLink>
            </nav>
            <div className={`${theme.ll_container} ${styles.ll_footer_container}`}>
                <div className={styles.ll_footer_link_wrap}>
                    <ul className={styles.ll_footer_slinks}>
                        <li className={styles.ll_footer_slink}><NavLink className={(nav) => nav.isActive ? `${styles.link_active}` : ''} to="/info/about-us">எங்களை பற்றி</NavLink></li>
                        <li className={styles.ll_footer_slink}><NavLink className={(nav) => nav.isActive ? `${styles.link_active}` : ''} to="/info/terms-of-use">விதிமுறைகள்</NavLink></li>
                        <li className={styles.ll_footer_slink}><NavLink className={(nav) => nav.isActive ? `${styles.link_active}` : ''} to="/info/privacy-policy">தனியுரிமைக்-கொள்கை</NavLink></li>
                        <li className={styles.ll_footer_slink}><NavLink className={(nav) => nav.isActive ? `${styles.link_active}` : ''} to="/requests">உதவி-மையம்</NavLink></li>
                    </ul>
                    <p className={styles.ll_footer_copyright}>
                        <a className={styles.ll_footer_link} href="mailto:deepakr22@gmail.com">&copy; LearnLike's Tamizh 2023.</a>
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;