import ReactJWPlayer from 'react-jw-player';

const PlayerInstance = (props) => {
    return (
        <div className="flex w-full">
            <div className="container">
                <ReactJWPlayer
                    playerId="ll_tamizh_masterr_video_player"
                    playerScript="https://cdn.jwplayer.com/libraries/Xt5rBv5b.js"
                    file={props.context.videoUrl}
                    image={props.context.videoThumbnail}
                    customProps={{
                        advertising: {
                            client: "vast",
                            schedule: {
                                adBreak1: {
                                    tag: "/ads/preroll.xml"
                                }
                            }
                        }
                    }}
                >

                </ReactJWPlayer>
            </div>
        </div>
    );
}

export default PlayerInstance;