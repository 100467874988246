import theme from '../styles/theme.module.scss'

const MainContent = (props) => {
    return (
        <main className={`ll_main_content ${theme.ll_main_content}`}>
            {props.children}
        </main>
    );
}

export default MainContent;