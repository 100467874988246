import style from '../styles/header.module.scss'
import BrandLogo from '../static/ll_tamizh_logo.svg'
import { Link, NavLink } from 'react-router-dom';
import Settings from './svgs/settings'
import Logout from './svgs/logout'
import Moon from './svgs/moon';
import { setAuthToken } from '../helpers/authToken';
import { useContext, useEffect, useState } from 'react';
import { CoreContext } from '../config/context';

const Header = (props) => {
    const globals = useContext(CoreContext).CoreState.udata;

    const toggleSidebar = (event) => {
        event.preventDefault()

        const bodyNode = document.body;
        const headerNode = document.getElementById('ll_sidebar_instance');

        bodyNode.classList.toggle('overlay-active')
        headerNode.classList.toggle('active')
    };

    const toggleDropdown = (event) => {
        event.preventDefault()

        const triggerNode = document.getElementById('ll_profile_trigger');

        triggerNode.classList.toggle(`${style.active}`)
    };

    return (
        <header className={style.ll_header}>
            <div className={style.ll_header_wrap}>
                <a href="./" className={style.ll_hamburger} onClick={toggleSidebar}>
                    <div className={style.ll_hamburger_wrap}>
                        <span className={style.llHamburger_line_1}></span>
                        <span className={style.llHamburger_line_2}></span>
                        <span className={style.llHamburger_line_3}></span>
                    </div>
                </a>
                <Link to="/" className={style.ll_brand}>
                    <img src={BrandLogo} className={style.ll_brand_logo} alt="Learn Like"></img>
                    <h1 className="sr-only">LearnLike</h1>
                </Link>
                <div className={style.ll_sec_nav_icons_wrap}>
                    <Link to="/notifications" className={`${style.ll_secondary_icon} ${style.newNotification}`}>
                        <img src="/images/icons/notification-color.png"  alt="Notifications"></img>
                    </Link>
                    <a href="./" className={`${style.ll_secondary_icon} ${style.ll_dropdown_trigger}`} onClick={toggleDropdown}>
                        {/* <img src="/images/icons//avatar.jpg"  alt="User Profile"></img> */}
                        <img src={globals.uavatar}  alt="User Profile"></img>
                        <ul id="ll_profile_trigger" className={style.ll_dropdown_menu}>
                            <li>
                                <NavLink to={`/profile`} alt="சுயவிவரம்">
                                    <div className='flex px-4 py-2 pointer-events-none cursor-default bg-gray-200'>
                                        {/* <img className={style.ll_img_sec} src="/images/icons//avatar.jpg"  alt="User Profile"></img> */}
                                        <img className={style.ll_img_sec} src={globals.uavatar} alt="User Profile"></img>
                                        <div className='flex flex-col px-3 justify-center'>
                                            <span className='text-base font-semibold text-gray-700 mr-2'>{ globals.uname }</span>
                                            <span className='text-sm font-normal text-gray-500'>{ `${globals.ugrade} - ${globals.ucontextid}` }</span>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>
                            <li>
                                <div className={ style.ll_dropdown_menuitem }>
                                    <Moon/>
                                    <span className={style.ll_label}>தளத்தின் தோற்றம்</span>
                                </div>
                            </li>
                            <li>
                                <NavLink to={`/settings`} className={(navData) => navData.isActive ? `${style.ll_dropdown_menuitem} ${style.isActiveLink}` : style.ll_dropdown_menuitem }>
                                    <Settings/>
                                    <span className={style.ll_label}>அமைப்புகள்</span>
                                </NavLink>
                            </li>
                            <li>
                                <div  href="test" 
                                    className={ style.ll_dropdown_menuitem } 
                                    onClick={ (e) => {
                                        e.preventDefault();
                                        setAuthToken(false);
                                    }}>
                                    <Logout/>
                                    <span className={style.ll_label}>வெளியேறு</span>
                                </div>
                            </li>
                        </ul>
                    </a>
                </div>
            </div>
        </header>
    );
}

export default Header;