import { Children, cloneElement } from "react";
import theme from '../styles/theme.module.scss'
import styles from '../styles/pieChart.module.scss'

const PieGroupLayout = (props, children) => {
    const Comps = props.children;

    return (
        <section className={styles.ll_pie_chart_group}>
            <div className={theme.ll_container}>
                <div className={styles.ll_pie_chart_container}>
                    <div className={styles.ll_pie_chart_wrapper}>{cloneElement(Comps[0])}</div>
                    <div className={styles.ll_pie_chart_wrapper}>{cloneElement(Comps[1])}</div>
                    <div className={styles.ll_pie_chart_wrapper}>{cloneElement(Comps[2])}</div>
                </div>
            </div>
        </section>
    );
};

export default PieGroupLayout;