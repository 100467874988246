import theme from '../styles/theme.module.scss'
import styles from '../styles/qwicksCards.module.scss'

import qwicksData from '../data/qwicksData.json';

const QwicksCards = (props) => {

    const cards = qwicksData.map((card) => {
        return (
            <li className={styles.ll_qwicks_card}>
                <div className={styles.ll_img_wrap}>
                    <img className={styles.ll_qwicks_thumbnail} src={card.thumbnail} alt={card.title}></img>
                </div>
                <div className={styles.ll_info}>
                    <img className={styles.ll_icon} src="/images/icons/play-mini.png"></img>
                    <p className={styles.ll_duration}>{card.duration}</p>
                </div>
            </li>
        );
    });

    return (
        <div className={styles.ll_qwicks_cards}>
            <div className={theme.ll_container}>
                <div className='flex w-full'>
                    <h3 className='text-lg lg:text-xl font-semibold text-left mb-4'>Qwicks</h3>
                </div>
                <ul className={styles.ll_qwicks_cards_list}>
                    {cards}
                </ul>
            </div>
        </div>
    );
}

export default QwicksCards;