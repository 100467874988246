import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import PageLayout from "../layouts/pageLayout";
import RollingSlides from "../components/rollingSlides";
import HeroCards from "../components/heroCards";
import VideoTiles from "../components/videoTiles";
import { baseURL } from "../App";
import endpoints from '../data/api.json'
import UpdateUserContext from "../helpers/updateUserContext";
import Loader from "../components/loader";
import ActionableItems from "../components/actionableItems";
import Breadcrumbs from "../components/breadcrumbs";
import Fallback from "../components/fallbackPage";
import BasicTabs from "../components/basicTabs";
import TermsOfUse from "../static/views/termsOfUse";
import PrivacyPolicy from "../static/views/privacyPolicy";
import AboutUs from "../static/views/aboutUs";
import { useParams } from "react-router-dom";
import TabbedContainer from "../layouts/tabbedContainer";
import InfoIcon from "../components/svgs/quiz";

const Info = () => {
    const { contentType } = useParams();

    const [ compContext, setContext ] = useState({});
    const [ loading, setLoading ] = useState(true);

    const crumbs = [
        { name: 'முகப்பு', path: '/' },
        { name: 'தகவல் பகுதி', path : `/info` }
    ];

    const tabContent = [
        { id: 0, pathname: '/info/terms-of-use', link: 'terms-of-use', name: 'பயன்பாட்டு விதிமுறைகள்', contentId: <TermsOfUse/>, contentIcon: <InfoIcon/> },
        { id: 1, pathname: '/info/privacy-policy', link: 'privacy-policy', name: 'தனியுரிமைக் கொள்கை', contentId: <PrivacyPolicy/>, contentIcon: <InfoIcon/> },
        { id: 2, pathname: '/info/about-us', link: 'about-us', name: 'எங்களை பற்றி', contentId: <AboutUs/>, contentIcon: <InfoIcon/> }
    ];

    const activeTabs = tabContent.filter((item) => item.link === contentType);
    const activeTab = (activeTabs.length > 1 || activeTabs.length < 0) ? 0 : activeTabs[0].id

    return (
        <PageLayout>
            { loading?
                (
                    <>
                        <Breadcrumbs context={crumbs}></Breadcrumbs>
                        {/* <BasicTabs
                            tabs={ Tabs }
                            activeTabs={ Content[contentType].tab }
                            views={ Content[contentType].view }
                        /> */}
                        <TabbedContainer deepLinking={true} active={activeTab} context={tabContent}></TabbedContainer>
                    </>
                )
                :
                (
                    <Loader></Loader>
                )
            }
        </PageLayout>
    );
}

export default Info;