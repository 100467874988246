import theme from '../styles/theme.module.scss'
import styles from '../styles/statCards.module.scss'

const StatCards = (props) => {
    console.log('props.context', props.context);
    const cards = Object.values(props.context).map((stat) => {
        return (
            <li className={`${styles.ll_stat_card_item} ll_stat_card_item ${styles[stat.theme]}`}>
                <div className={styles.ll_stat_value}>{stat.value}</div>
                <div className={styles.ll_stat_label}>{stat.label}</div>
            </li>
        );
    });

    return (
        <section className={`${styles.ll_stat_cards} ll_stat_cards`}>
            <div className={theme.ll_container}>
                <ul className={styles.ll_stat_card_wrap}>
                    {cards}
                </ul>
            </div>
        </section>
    );
};

export default StatCards;