import styles from '../../styles/typography.module.scss';
import roadmapImage from '../../static/Learnlike_roadmap.png';


const AboutUs = () => {
    return (
        <div className='px-4 py-5'>
            <div className={styles.ll_typography}>
                <h3>எங்களை பற்றி</h3>
                <h4>Learnlike Mission</h4>
                <p>"To engage and educate the young generation by offering in-depth learning opportunities."</p>
                
                <h4>Learnlike Vision</h4>
                <p>"Be world-leading educators, where students from across the country will have access to a variety of learning applications to excel in their curriculum."</p>

                <img src={roadmapImage} alt="LearnLike"></img>
            </div>
        </div>
    );
};

export default AboutUs;