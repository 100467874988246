import logo from './logo.svg';
import './App.css';
import React, { useContext, useReducer } from 'react'; 
import { setAuthToken } from './helpers/authToken';

import { BrowserRouter as Router } from 'react-router-dom'; 
import Routers from './config/routes';
import ScrollToTop from './helpers/scrollToTop';

import { CoreContext, updateContext } from './config/context';
import axios from 'axios';

// export const baseURL = "http://192.168.84.102/LL_Tamizh_Server/api/"; // Local DEV
// export const baseURL = "http://10.244.244.102/LL_Tamizh_Server/api/"; // Remote DEV
export const baseURL = "https://tamizhapp.in/api/"; // Remote PROD
// export const baseURL = "/api/"; // Prod Build

function App() {
  const token = localStorage.getItem("JWToken");

  const [ coreState, coreDispatch ] = useReducer( updateContext, { udata: {}, theme: 'light' });

  // axios.defaults.withCredentials = true;

  if (token) setAuthToken(token);
  
  return (
    <div className="App">
      <CoreContext.Provider value={{ CoreState: coreState, CoreDispatch: coreDispatch }}>
        <Router>
          <Routers/>
          <ScrollToTop/>
        </Router>
      </CoreContext.Provider>
    </div>
  );
}

export default App;
