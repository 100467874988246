import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import PageLayout from "../layouts/pageLayout";
import { baseURL } from "../App";
import endpoints from '../data/api.json'
import Loader from "../components/loader";
import Breadcrumbs from "../components/breadcrumbs";
import generatePayload from "../helpers/generatePayload";
import { CoreContext } from "../config/context";
import FilterableTabs from "../components/filterableTabs";
import EventCards from "../components/eventCards";

const Events = () => {
    const globals = useContext(CoreContext);

    const [ compContext, setContext ] = useState({});
    const [ loading, setLoading ] = useState(false);

    const payload = {
        uid: localStorage.getItem('JWUid'),
        sid: localStorage.getItem('JWToken')
    };

    const crumbs = [
        { name: 'முகப்பு', path: '/' },
        { name: 'நிகழ்வுகள்', path : `/events` }
    ];

    const eventsData = {
        filters: [
            { key: 'upcoming', label: 'வரவிருப்பவை' },
            { key: 'pinned', label: 'சேமித்தவை' },
            { key: 'registered', label: 'பதிவு செய்தவை' },
            { key: 'past', label: 'நிறைவுற்றவை' },
        ],
        data: {
            LL001G: {
                filter: "pinned,upcoming",
                title: 'LearnLike Day 2023',
                image: 'https://mir-s3-cdn-cf.behance.net/project_modules/fs/53e08e121423109.60c5d065c6c74.jpg',
                date: {
                    value: '21-June-2023',
                    labels: { date: 21, month: 'June', year: 2023, day: 'Friday' }
                },
                createdBy: 'Deepak Ravikumar, LearnLike',
                location: 'Ramanathapuram, Coimbatore.',
                attendees: {
                    count: 36,
                    avatars: [
                        { avatar: '/images/avatars/3.png' },
                        { avatar: '/images/avatars/2.png' },
                        { avatar: '/images/avatars/1.png' }
                    ]
                }
            },
            TEDU00G: {
                filter: "upcoming,registered",
                title: 'டைம்ஸ் கல்வி கண்காட்சி',
                image: 'https://cdn2.allevents.in/thumbs/thumb5e5f875e20f9f.jpg',
                date: {
                    value: '10-July-2023',
                    labels: { date: 10, month: 'July', year: 2023, day: 'Thursday' }
                },
                createdBy: 'Pradeep, Times Of India',
                location: 'Suguna Auditorium, Coimbatore.',
                attendees: {
                    count: 51,
                    avatars: [
                        { avatar: '/images/avatars/2.png' },
                        { avatar: '/images/avatars/3.png' },
                        { avatar: '/images/avatars/5.png' },
                        { avatar: '/images/avatars/1.png' },
                        { avatar: '/images/avatars/4.png' },
                    ]
                }
            },
            DVZH001: {
                filter: "pinned",
                title: 'தினமலர் வழிகாட்டி 23',
                image: 'https://i.ytimg.com/vi/2cwa3VFzV3o/maxresdefault.jpg',
                date: {
                    value: '09-Sep-2023',
                    labels: { date: 23, month: 'Oct', year: 2023, day: 'Monday' }
                },
                createdBy: 'PRO, Dinamalar',
                location: 'KIT College, Coimbatore.',
                attendees: {
                    count: 28,
                    avatars: [
                        { avatar: '/images/avatars/1.png' },
                        { avatar: '/images/avatars/4.png' },
                    ]
                }
            },
            DVZH002: {
                filter: "past,registered",
                title: 'செயற்கை நுண்ணறிவு மாநாடு',
                image: 'https://www.pda.org/images/default-source/default-album/eu_conferences/2023/robotics-and-automation/2023-ra-li_1200x628.jpg',
                date: {
                    value: '03-May-2023',
                    labels: { date: 3, month: 'May', year: 2023, day: 'Tuesday' }
                },
                createdBy: 'Convenor, SSIET.',
                location: 'SSIET College, Coimbatore.',
                attendees: {
                    count: 16,
                    avatars: [
                        { avatar: '/images/avatars/5.png' },
                        { avatar: '/images/avatars/1.png' },
                    ]
                }
            },
            DVZH003: {
                filter: "upcoming,registered",
                title: 'ஆன்லைன் கல்வி கண்காட்சி',
                image: 'http://www.asianuniversities.org/edufair/images/THE.png',
                date: {
                    value: '24-Sep-2022',
                    labels: { date: 24, month: 'Sep', year: 2023, day: 'Saturday' }
                },
                createdBy: 'Convenor, SSIET.',
                location: 'THE Campus, Chennai.',
                attendees: {
                    count: 60,
                    avatars: [
                        { avatar: '/images/avatars/2.png' },
                        { avatar: '/images/avatars/4.png' },
                        { avatar: '/images/avatars/5.png' },
                        { avatar: '/images/avatars/2.png' },
                        { avatar: '/images/avatars/3.png' },
                        { avatar: '/images/avatars/1.png' },
                    ]
                }
            },
            DVZH004: {
                filter: "past,pinned",
                title: 'புத்தக கண்காட்சி',
                image: 'https://www.nbtindia.gov.in/ndwbf2023/assetsndwbf2023/images/banner-1.jpg',
                date: {
                    value: '03-Feb-2022',
                    labels: { date: 3, month: 'Feb', year: 2023, day: 'Sunday' }
                },
                createdBy: 'Chairman, NBT India.',
                location: 'Rajasthani Sangh, Coimbatore.',
                attendees: {
                    count: 44,
                    avatars: [
                        { avatar: '/images/avatars/4.png' },
                        { avatar: '/images/avatars/5.png' },
                        { avatar: '/images/avatars/2.png' },
                        { avatar: '/images/avatars/1.png' },
                    ]
                }
            },
            LL001G1: {
                filter: "upcoming,registered",
                title: 'LearnLike Day 2023',
                image: 'https://www.deltapsu.com/uploads_delta/5eb2370b59402editor_201912111576046163324344.jpg',
                date: {
                    value: '21-June-2023',
                    labels: { date: 21, month: 'June', year: 2023, day: 'Friday' }
                },
                createdBy: 'Deepak Ravikumar, LearnLike',
                location: 'Ramanathapuram, Coimbatore.',
                attendees: {
                    count: 36,
                    avatars: [
                        { avatar: '/images/avatars/5.png' },
                        { avatar: '/images/avatars/4.png' },
                        { avatar: '/images/avatars/3.png' },
                    ]
                }
            },
            TEDU00G2: {
                filter: "past",
                title: 'டைம்ஸ் கல்வி கண்காட்சி',
                image: 'https://ischoolconnect.com/blog/wp-content/uploads/2022/08/Climate-change.png',
                date: {
                    value: '10-July-2023',
                    labels: { date: 10, month: 'July', year: 2023, day: 'Thursday' }
                },
                createdBy: 'Pradeep, Times Of India',
                location: 'Suguna Auditorium, Coimbatore.',
                attendees: {
                    count: 51,
                    avatars: [
                        { avatar: '/images/avatars/2.png' },
                        { avatar: '/images/avatars/3.png' },
                        { avatar: '/images/avatars/5.png' },
                        { avatar: '/images/avatars/1.png' },
                        { avatar: '/images/avatars/4.png' },
                    ]
                }
            },
            DVZH0013: {
                filter: "pinned",
                title: 'தினமலர் வழிகாட்டி 23',
                image: 'https://img.dinamalar.com/images/share-img-vazhikati-2022-new2.jpg',
                date: {
                    value: '09-Sep-2023',
                    labels: { date: 23, month: 'Oct', year: 2023, day: 'Monday' }
                },
                createdBy: 'PRO, Dinamalar',
                location: 'KIT College, Coimbatore.',
                attendees: {
                    count: 28,
                    avatars: [
                        { avatar: '/images/avatars/1.png' },
                        { avatar: '/images/avatars/4.png' },
                    ]
                }
            },
            DVZH0024: {
                filter: "registered,pinned",
                title: 'செயற்கை நுண்ணறிவு மாநாடு',
                image: 'https://www.analyticsinsight.net/wp-content/uploads/2021/12/Top-10-Artificial-Intelligence-Courses-for-Beginners-in-2022.jpg',
                date: {
                    value: '03-May-2023',
                    labels: { date: 3, month: 'May', year: 2023, day: 'Tuesday' }
                },
                createdBy: 'Convenor, SSIET.',
                location: 'SSIET College, Coimbatore.',
                attendees: {
                    count: 16,
                    avatars: [
                        { avatar: '/images/avatars/5.png' },
                        { avatar: '/images/avatars/1.png' },
                    ]
                }
            },
            DVZH0035: {
                filter: "past",
                title: 'ஆன்லைன் கல்வி கண்காட்சி',
                image: 'https://delhi-fun-dos.com/wp-content/uploads/2022/07/kitab-lovers-book-fair-in-Delhi.jpg',
                date: {
                    value: '24-Sep-2022',
                    labels: { date: 24, month: 'Sep', year: 2023, day: 'Saturday' }
                },
                createdBy: 'Convenor, SSIET.',
                location: 'THE Campus, Chennai.',
                attendees: {
                    count: 60,
                    avatars: [
                        { avatar: '/images/avatars/4.png' },
                        { avatar: '/images/avatars/5.png' },
                        { avatar: '/images/avatars/2.png' },
                        { avatar: '/images/avatars/3.png' },
                        { avatar: '/images/avatars/1.png' },
                        { avatar: '/images/avatars/2.png' },
                    ]
                }
            },
            DVZH0046: {
                filter: "upcoming,pinned,registered",
                title: 'புத்தக கண்காட்சி',
                image: 'https://venngage-wordpress.s3.amazonaws.com/uploads/2022/01/Cultural-Event-Poster-Design.png',
                date: {
                    value: '03-Feb-2022',
                    labels: { date: 3, month: 'Feb', year: 2023, day: 'Sunday' }
                },
                createdBy: 'Chairman, NBT India.',
                location: 'Rajasthani Sangh, Coimbatore.',
                attendees: {
                    count: 44,
                    avatars: [
                        { avatar: '/images/avatars/4.png' },
                        { avatar: '/images/avatars/5.png' },
                        { avatar: '/images/avatars/2.png' },
                        { avatar: '/images/avatars/3.png' }
                    ]
                }
            },
        }
    };
    

    const fetchData = () => {

        const payload = {
            uid: localStorage.getItem('JWUid'),
            sid: localStorage.getItem('JWToken')
        };

        axios.post(`${baseURL}${endpoints.fetchLibrary}`, generatePayload(payload))
            .then(response => {
                const token = response.data.token;

                if (!token) {
                    window.location.href = '/login';
                } else {
                    setContext(response.data);

                    globals.CoreDispatch({
                        udata: response.data.udata,
                        theme: globals.CoreState['theme']
                    });

                    setLoading(true);
                }

                localStorage.setItem("JWToken", token);

                setAuthToken(token);

                document.title = `தமிழ் - 📅 நிகழ்வுகள்`;
            })
            .catch(error => {
                console.log(error)
                setAuthToken(false);
            });
    };

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <PageLayout>
            { loading?
                (
                    <>
                        <Breadcrumbs context={crumbs} />
                        <FilterableTabs
                            title="நிகழ்வுகள்" filters={eventsData.filters}
                            defaultFilter="upcoming"
                            context={Object.values(eventsData.data)}
                            child="EventCards">
                        </FilterableTabs>
                    </>
                )
                :
                (
                    <Loader></Loader>
                )
            }
        </PageLayout>
    );
}

export default Events;