import styles from '../../styles/typography.module.scss';

const TermsOfUse = () => {
    return(
        <div className='px-4 py-5'>
            <div className={styles.ll_typography}>
                <h3>பயன்பாட்டு விதிமுறைகள் ("Terms")</h3>
                <p>Our Terms and Conditions were last updated on [DATE]</p>
                <p>Please read these terms and conditions carefully before using Our Service.</p>
                <h4>Interpretation and Definitions</h4>
                <h5>Interpretation</h5>
                <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                <h5>Definitions</h5>
                <p>For the purposes of these Terms and Conditions:</p>
                <ul>
                    <li><b>“Affiliate”</b> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
                    <li><b>“Account”</b> means a unique account created for You to access our Service or parts of our Service.</li>
                    <li><b>“Company”</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to [COMPANY_INFORMATION].</li>
                    <li><b>“Country”</b> refers to [COMPANY_COUNTRY].</li>
                    <li><b>“Content”</b> refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.</li>
                    <li><b>“Device”</b> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
                    <li><b>“Feedback”</b> means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.</li>
                    <li><b>“Products”</b> refer to the products or items offered for sale on the Service.</li>
                    <li><b>“Orders”</b> mean a request by You to purchase Products from Us.</li>
                    <li><b>“Promotions”</b> refer to contests, sweepstakes or other promotions offered through the Service.</li>
                    <li><b>“Service”</b> refers to the Website.</li>
                    <li><b>“Terms and Conditions”</b> (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. This Terms and Conditions Agreement was generated by TermsFeed Terms and Conditions Generator.</li>
                    <li><b>“Third-party Social Media Service”</b> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</li>
                    <li><b>“Website”</b> refers to [WEBSITE_NAME], accessible from [WEBSITE_URL]</li>
                </ul>
            </div>
        </div>
    );
}

export default TermsOfUse;