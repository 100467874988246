import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import PageLayout from "../layouts/pageLayout";
import RollingSlides from "../components/rollingSlides";
import HeroCards from "../components/heroCards";
import VideoTiles from "../components/videoTiles";
import { baseURL } from "../App";
import endpoints from '../data/api.json'
import UpdateUserContext from "../helpers/updateUserContext";
import Loader from "../components/loader";
import Breadcrumbs from "../components/breadcrumbs";
import generatePayload from "../helpers/generatePayload";
import { CoreContext } from "../config/context";

const Library = () => {
    const globals = useContext(CoreContext);

    const [ compContext, setContext ] = useState({});
    const [ loading, setLoading ] = useState(false);

    const crumbs = [
        { name: 'முகப்பு', path: '/' },
        { name: 'தொகுப்புகள்', path : `/library` }
    ];

    const fetchData = () => {

        const payload = {
            uid: localStorage.getItem('JWUid'),
            sid: localStorage.getItem('JWToken')
        };

        axios.post(`${baseURL}${endpoints.fetchLibrary}`, generatePayload(payload))
            .then(response => {
                const token = response.data.token;

                if (!token) {
                    window.location.href = '/login';
                } else {
                    setContext(response.data);

                    globals.CoreDispatch({
                        udata: response.data.udata,
                        theme: globals.CoreState['theme']
                    });

                    setLoading(true);
                }

                localStorage.setItem("JWToken", token);

                setAuthToken(token);

                document.title = `தமிழ் - 📚 தொகுப்புகள்`;
            })
            .catch(error => {
                console.log(error)
                setAuthToken(false);
            });
    };

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <PageLayout>
            { loading?
                (
                    <>
                        <Breadcrumbs context={crumbs}></Breadcrumbs>
                        {/* <VideoTiles variant="progressIndicator" title="தொடர்ந்து பார்க்க" showCta="false" context={compContext.uwatched}></VideoTiles> */}
                        <VideoTiles variant="recommendationList"  title="பரிந்துரைகள்" showCta="false" context={compContext.urecommend}></VideoTiles> 
                        <VideoTiles variant="recommendationList"  title="அதிகம் பார்க்கப்பட்டவை" showCta="false" context={compContext.urecommend}></VideoTiles> 
                    </>
                )
                :
                (
                    <Loader></Loader>
                )
            }
        </PageLayout>
    );
}

export default Library;