import theme from '../styles/theme.module.scss'
import styles from '../styles/filterableTabs.module.scss'
import { useState } from 'react';
import EventCards from './eventCards';
import ProductCards from './productCards';

const FilterableTabs = (props) => {
    const filterResults = (data, filter) => {
        return data.filter((item) => item.filter.split(',').includes(filter));
    };

    const [ contextData, filterContextData ] = useState(filterResults(props.context, props.defaultFilter));
    const [ filterState, toggleFilterState] = useState(0);

    const toggleFilter = (tabID, data) =>  {
        toggleFilterState(tabID);

        filterContextData(filterResults(props.context, data.key));
    }

    const getActiveFilter = (index, uri, className) => (filterState === index) ? className : '';

    const filters = props.filters.map((filter, index) => {
        return (
            <li id={`ll_filter_instance_${index}`}
                className={`${styles.ll_tab_anchor} ${ getActiveFilter(index, '', styles.active) }`}
                key={index}
                onClick={ () => toggleFilter(index, filter) }>
                {filter.label}
            </li>
        );
    });

    return (
        <section className={styles.ll_filterable_tabs}>
            <div className={theme.ll_container}>
                <div className={styles.ll_title_wrap}>
                    <h3 className={styles.ll_h3}>{props.title}</h3>
                </div>
                <div className={styles.ll_filter_container}>
                    <div className={styles.ll_tabs_container + ' ' + styles.ll_filters_container}>
                        <ul className={styles.ll_tabs}>
                            {filters}
                        </ul>
                    </div>
                    <div className={styles.ll_tabs_content}>
                        <div className={styles.ll_views}>
                            { props.child == 'ProductCards' ? <ProductCards context={contextData}></ProductCards> : ''}
                            { props.child == 'EventCards' ? <EventCards context={contextData}></EventCards> : ''}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FilterableTabs;