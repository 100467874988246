import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import PageLayout from "../layouts/pageLayout";
import RollingSlides from "../components/rollingSlides";
import HeroCards from "../components/heroCards";
import VideoTiles from "../components/videoTiles";
import { baseURL } from "../App";
import endpoints from '../data/api.json'
import UpdateUserContext from "../helpers/updateUserContext";
import Loader from "../components/loader";
import ActionableItems from "../components/actionableItems";
import Breadcrumbs from "../components/breadcrumbs";
import Fallback from "../components/fallbackPage";

const NotFound = () => {
    const [ compContext, setContext ] = useState({});
    const [ loading, setLoading ] = useState(true);


    const crumbs = [
        { name: 'முகப்பு', path: '/' },
        { name: 'அறிக்கைகள்', path : `/reports` }
    ];

    // const fetchData = () => {

    //     axios.get('/data/fetch/notifications-resp.json')
    //         .then(response => {
    //             const token = response.data.token;

    //             if (!token) {
    //                 window.location.href = '/login';
    //             } else {
    //                 setContext(response.data);
    //                 console.log(response.data);
    //                 setLoading(true);
    //             }

    //             localStorage.setItem("JWToken", token);

    //             setAuthToken(token);

    //             document.title = `தமிழ் - 📈 அறிக்கைகள்`;
    //         })
    //         .catch(error => {
    //             console.log(error)
    //             setAuthToken(false);
    //         });
    // };

    // useEffect(() => {
    //     fetchData()
    // }, []);


    return (
        <PageLayout>
            { loading?
                (
                    <>
                        <Fallback
                            title="🚫 நீங்கள் தேடும் உள்ளடக்கம் கிடைக்கவில்லை"
                            image="https://cdn.iconscout.com/icon/free/png-256/404-page-not-found-456876.png"
                            message="மேலும் பல பயனுள்ள உள்ளடக்கங்களைக் காண முகப்புப் பக்கத்திற்குச் செல்லவும்."
                            redirect=""
                        />
                    </>
                )
                :
                (
                    <Loader></Loader>
                )
            }
        </PageLayout>
    );
}

export default NotFound;