import Slider from "react-slick";

import theme from '../styles/theme.module.scss'
import styles from '../styles/adsThreeColumnCards.module.scss'

const AdThreeColumnContent = (props) => {

    return (
        <section className={`${styles.ll_three_col_cards} ll_three_col_cards`}>
            <div className={`${theme.ll_container} ${styles.ll_fullWidth_container}`}>
                <div className={styles.ll_content_wrapper}>
                    <div className={styles.ll_img_wrap}>
                        <img className={styles.ll_img} src="/images/ad-banners/horizontal/kclas.jpeg"></img>
                    </div>
                    <div className={styles.ll_text_wrap}>
                        <h3 className={styles.ll_title}>கல்வித் துறையில் பல ஆண்டுகளாக சாதனை படைத்துக்கொண்டிருக்கும் கலை மற்றும் அறிவியல் கல்லூரியில் சேர விரும்புகிறீர்களா?</h3>
                        <p className={styles.ll_caption}>இன்றே <b>குமரகுரு காலேஜ் ஆஃப் லிபரல் ஆர்ட்ஸ் & சயின்சிற்க்கு</b> வருகை புரிந்து தங்களது இடத்தை முன்பதிவு செய்துகொள்ளுங்கள்!</p>
                        <div className={styles.ll_cta_wrap}>
                            <a className={styles.ll_cta_link} href="https://www.kclas.ac.in/" target="new">முன்பதிவு செய்</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AdThreeColumnContent;