import styles from '../styles/eventCards.module.scss'

const EventCards = (props) => {
    const cards = props.context.map((card) => {
        const avatarStack = card.attendees.avatars.map((attendee) => {
            return (
                <img className={styles.ll_stack_img} src={attendee.avatar} alt="Attendee"></img>
            );
        });

        return (
            <li className={styles.ll_event_card}>
                {/* <div className="w-full flex flex-col"> */}
                    <div className={styles.ll_img_wrap}>
                        <img className={styles.ll_img_cover} src={card.image} alt={card.title}></img>
                    </div>
                    <div className={styles.ll_content_wrap}>
                        <div className={styles.ll_info_wrap}>
                            <h4 className={styles.ll_card_title}>{card.title}</h4>
                            <p className={styles.ll_card_info}>
                                <span className={styles.ll_info_icon}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                </span>
                                <span className={styles.ll_info_label}>{card.createdBy}</span>
                            </p>

                            <p className={styles.ll_card_info}>
                                <span className={styles.ll_info_icon}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                                </span>
                                <span className={styles.ll_info_label}>{card.location}</span>
                            </p>
                        </div>
                        <p className={styles.ll_date_card}>
                            <span className={styles.ll_month}>{card.date.labels.month}</span>
                            <span className={styles.ll_date}>{card.date.labels.date}</span>
                            <span className={styles.ll_day}>{card.date.labels.day}</span>
                        </p>
                        <div className={styles.ll_card_footer}>
                            <div className={styles.avatar_stack}>
                                {avatarStack}
                                <span className={styles.ll_stack_info}>
                                    {card.attendees.count}
                                </span>
                            </div>
                            <button className={styles.btn_style} alt="Test" href="#test">
                                பதிவு செய்
                            </button>
                        </div>
                    </div>
                {/* </div> */}
            </li>
        );
    });

    return <ul className={styles.ll_event_cards}>{cards}</ul>;
};

export default EventCards;