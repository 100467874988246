import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import PageLayout from "../layouts/pageLayout";
import RollingSlides from "../components/rollingSlides";
import AdBannerHorizontalCards from "../components/adBannerHorizontalCards";
import AdBannerRollingVideos from "../components/adBannerRollingVideos";
import HeroCards from "../components/heroCards";
import VideoTiles from "../components/videoTiles";
import { baseURL } from "../App";
import endpoints from '../data/api.json'
import UpdateUserContext from "../helpers/updateUserContext";
import Loader from "../components/loader";
import generatePayload from "../helpers/generatePayload";
import primaryAds from '../data/primaryAds.json';
import secondaryAds from '../data/secondaryAds.json';
import collegeAds from '../data/collegePromos.json';
import { CoreContext } from "../config/context";
import AdThreeColumnContent from "../components/adThreeColumnContent";
import QwicksCards from "../components/qwicksCards";

const Home = () => {
    const globals = useContext(CoreContext);

    const [ compContext, setContext ] = useState({});
    const [ loading, setLoading ] = useState(false);

    const fetchData = () => {

        const payload = {
            sid: localStorage.getItem("JWToken"),
            uid: localStorage.getItem("JWUid")
        };

        axios.post(`${baseURL}${endpoints.fetchLanding}`, generatePayload(payload))
            .then(response => {
                const token = response.data.token;

                if (!token) {
                    window.location.href = '/login';
                } else {
                    setContext(response.data);

                    globals.CoreDispatch({
                        udata: response.data.udata,
                        theme: globals.CoreState['theme']
                    });

                    setLoading(true);
                }

                localStorage.setItem("JWToken", token);
                localStorage.setItem("JWUid", response.data.uid);

                setAuthToken(token);

                document.title = `தமிழ் - 🙏 வணக்கம் ${response.data.udata.uname}`;
            })
            .catch(error => {
                console.log(error)
                setAuthToken(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <PageLayout>
            { loading?
                (
                    <>
                        {/* Primary ads */}
                        <AdBannerHorizontalCards context={primaryAds}></AdBannerHorizontalCards>

                        {/* Subjects */}
                        <HeroCards variant="default" title="பாடங்கள்" context={compContext.ucontext}></HeroCards>

                        {/* Continue watching */}
                        {
                            // compContext.uwatched
                            compContext.urecommend
                            ? <VideoTiles variant="progressIndicator" title="தொடர்ந்து பார்க்க" showCta="true" context={compContext.urecommend}></VideoTiles>
                            : <></>
                        }

                        <AdThreeColumnContent bgColor="025ca8"></AdThreeColumnContent>

                        {/* Secondary ads */}
                        {/* <RollingSlides context={compContext.upromoslides}></RollingSlides> */}

                        {/* Shorts */}

                        {/* Qwicks */}
                        <QwicksCards></QwicksCards>  

                        {/* Trending */}
                        {
                            compContext.urecommend
                            ? <VideoTiles variant="recommendationList"  title="பிரபலமானவை" showCta="true" context={compContext.urecommend}></VideoTiles> 
                            : <></>
                        } 

                        {/* Promos */}
                        <AdBannerRollingVideos context={collegeAds} title="கல்லூரிகள் 360°"></AdBannerRollingVideos>

                        {/* Events */}

                        {/* Tertiary ads */}
                        <AdBannerHorizontalCards context={secondaryAds} title="ஸ்பான்சர் செய்யப்பட்ட விளம்பரங்கள்"></AdBannerHorizontalCards>
                    </>
                )
                :
                (
                    <Loader></Loader>
                )
            }
        </PageLayout>
    );
}

export default Home;