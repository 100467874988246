import theme from '../styles/theme.module.scss';
import styles from '../styles/tabbedContainer.module.scss';
import { useEffect, useState } from 'react';
import Reports from '../components/svgs/reports';
import Requests from '../components/svgs/requests';
import { useNavigate, useLocation, Link, useParams } from 'react-router-dom';

const TabbedContainer = (props) => {
    const compContext = props.context;
    const deepLinking = props.deepLinking;
    const location = useLocation();

    const [ activetab, setActiveTab ] = useState(
        props.active && (props.active <= props.context.length)
        ? props.active
        : props.context[0].id
    );

    const handleTabChange = (id) => {
        setActiveTab(id);
    };

    useEffect(() => {
        if (deepLinking) {
            setActiveTab(compContext.filter((item) => item.pathname === location.pathname)[0].id);
        }
    }, [location]);

    const tabControls = compContext.map((value, key) => {
        const itemId = value.id;

        return (
                deepLinking
                ? 
                    <Link to={`${value.pathname}`}>
                        <li className={`${styles.tab_controls_item} ${ (activetab === itemId) ? styles.anchor_active : ''}`}
                            key={`view_${itemId.toString()}`}>
                                {value.contentIcon}
                                {value.name}
                        </li>
                    </Link>
                :
                    <li className={`${styles.tab_controls_item} ${ (activetab === itemId) ? styles.anchor_active : ''}`}
                        key={`view_${itemId.toString()}`}
                        onClick={ () => handleTabChange(value.id) }>
                            {value.contentIcon}
                            {value.name}
                    </li>
        );
    });

    const tabContents = compContext.map((value, key) => {
        const itemId = value.id;
        const classList = `${styles.tab_content_item} ${ (activetab === itemId) ? styles.content_active : ''}`;

        return (
            <li className={classList} key={`control_${value.id.toString()}`}>
                <div className={styles.ll_content_item_wrapper}>{value.contentId}</div>
            </li>
        );
    });

    return (
        <section className={`${styles.ll_tabbed_container} ll_tabbed_container`}>
            <div className={theme.ll_container}>
                <div className={styles.tab_wrapper}>
                    <ul className={styles.tab_controls}>{tabControls}</ul>
                    <ul className={styles.tab_contents}>{tabContents}</ul>
                </div>
            </div>
        </section>
    );
}

export default TabbedContainer;