import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import Nav from "../components/nav";
import PageLayout from "../layouts/pageLayout";
import Breadcrumbs from "../components/breadcrumbs";
import SubjectIndex from "../components/subjectIndex";
import VideoGrids from "../components/videoGrid";
import { useParams } from "react-router-dom";
import endpoints from '../data/api.json';
import { baseURL } from "../App";
import generatePayload from "../helpers/generatePayload";
import Loader from "../components/loader";
import { CoreContext } from "../config/context";

const TopicListing = () => {
    const globals = useContext(CoreContext);

    const [ compContext, setContext ] = useState({});
    const [ crumbs, setCrumbs ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    const { subjectCode, unitCode, topicCode } = useParams();

    const fetchData = () => {
        const unitsPayload = {
            uid: localStorage.getItem('JWUid'),
            sid: localStorage.getItem('JWToken'),
            subjectID: subjectCode,
            topicID: topicCode
        };

        axios.post(`${baseURL}/${endpoints.fetchSubTopics}`, generatePayload(unitsPayload))
            .then(response => {
                const token = response.data.token;

                console.log(subjectCode, unitCode, topicCode);

                if (!token) {
                    window.location.href = '/login';
                } else {
                    setContext(response.data);

                    const context = response.data.ucontext[topicCode];

                    globals.CoreDispatch({
                        udata: response.data.udata,
                        theme: globals.CoreState['theme']
                    });

                    setCrumbs([
                        {
                            name: 'முகப்பு',
                            path: '/'
                        },
                        { name: context.subjectName, path : `/${context.subjectID}` },
                        { name: context.unitName, path: `/${context.subjectCode}/${context.unitCode}/${context.topicCode}` }
                    ]);

                    setLoading(true);
                }

                localStorage.setItem("JWToken", token);

                setAuthToken(token);

                document.title = `தமிழ் - 📖 ${response.data.ucontext[topicCode].unitName}`;
            })
            .catch(error => {
                console.log(error)
                setAuthToken(false);
            });
    };

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <PageLayout>
            { loading ?
                (
                    <>
                        <Breadcrumbs context={crumbs}></Breadcrumbs>
                        <VideoGrids variant="default" title={compContext.ucontext[topicCode].topicName} context={compContext.ucontext[topicCode]}></VideoGrids>
                    </>
                )
                :
                (
                    <Loader></Loader>
                )
            }
        </PageLayout>
    );
}

export default TopicListing;