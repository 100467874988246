import styles from '../styles/loader.module.scss'
import loaderImage from '../static/loader-tx.gif'

const Loader = () => {
    return (
        <div className={styles.ll_loader}>
            <img className={styles.ll_loader_img} alt="Loading data for you..." src={loaderImage}></img>
        </div>
    );
}

export default Loader;