import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const PieChartInstance = (props) => {
      const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", '#6f42c1'];

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const payloadObj = payload[0].payload;

            return (
                <div className="custom-tooltip bg-white border-2 border-purple-600 rounded-md px-4 py-3">
                    <p className="label block">
                        <span>You have completed</span>
                    </p>
                    <p className="label">
                        <span className="font-bold">{` ${payloadObj.value} units `}</span>
                        <span>in</span>
                        <span className="font-bold">{` ${payloadObj.name}.`}</span>
                    </p>
                </div>
            );
        }
        return null;
    };


    const legendFormatter = ((value, entry, index) => {
        console.log(value, entry, index);
        return (
            <div className="text-sm inline-block my-2">{value}</div>
        );
    });
      

    return (
        <div className="w-full">
            <div className='flex w-full left-4 top-3 text-gray-900 border-b-2 border-gray-200'>
                <h3 className='text-lg lg:text-xl font-semibold text-left px-4 py-3'>{props.title}</h3>
            </div>
            <ResponsiveContainer height={250} width="99%">
                <PieChart id={`piechart-${props.key}`}>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Pie dataKey="value" data={props.context} fill="#8884d8" label
                        cx="50%" cy="50%" innerRadius={50} outerRadius={80}
                        paddingAngle={5}
                        >

                        {props.context.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    {/* <Legend iconType="circle" formatter={legendFormatter}/> */}
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}

export default PieChartInstance;