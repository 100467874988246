import React, { useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import generatePayload from "../helpers/generatePayload";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

import styles from '../styles/login.module.scss'
import themes from '../styles/theme.module.scss'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BrandLogo from '../static/ll_tamizh_logo.svg'
import Key from "../components/svgs/key";
import Hash from "../components/svgs/hash";

import { baseURL } from "../App";
import endpoints from '../data/api.json';
import ButtonPrimary from "../components/partials/buttonPrimary";

const Login = () => {
    const navigate = useNavigate();
    const [ sid, setSid ] = useState({});
    const [ activeLoginType, setActiveLoginType ] = useState('otpLogin');
    const [ otpLoginView, toggleOtpLoginView ] = useState(false);
    const [ optLoginState, setOtpLoginState ] = useState('init');
    const [ mobileError, setMobileError ] = useState({ status: false, msg: '' });
    const [ otpError, setOtpError ] = useState({ status: false, msg: '' });
    const [ pwordError, setPwordError ] = useState({ status: false, msg: '' });
    const [ welcomeMessage, updatewelcomeMessage ] = useState('');
    const [ gotpButtonState, updateGotpButtonState ] = useState('default');
    const [ votpButtonState, updateVotpButtonState ] = useState('default');
    const [ pwordButtonState, updatePwordButtonState ] = useState('default');
    // const [ mobile, setMobile ] = useState('');
    // const [ otp, setOtp ] = useState('');
    // const [ uname, setUname ] = useState('');
    // const [ pword, setPword ] = useState('');

    const generateOTP = ( event ) => {
        event.preventDefault();
        event.currentTarget.blur();
        updateGotpButtonState('loading');

        const otpPayload = {
            msin: event.target.mobile.value,
            geo: "ABCD"
        };

        updateVotpButtonState('default');
        setOtpError({ status: false, msg: '' });

        axios.post(`${baseURL}${endpoints.generateOtp}`, generatePayload(otpPayload))
            .then(response => {
                if (response.data.status) {
                    toggleOtpLoginView(response.data.status);
                    setSid(response.data.sid);
                    updatewelcomeMessage(`${response.data.displayName}`);
                    updateGotpButtonState('default');
                    setMobileError({ status: false, msg: '' });
                } else {
                    setMobileError({ status: true, msg: response.data.msg });
                    updateGotpButtonState('error');
                } 
            })
            .catch(error => console.log(error) )
    };

    const verifyOTP = ( event ) => {
        event.preventDefault();
        event.currentTarget.blur();
        updateVotpButtonState('loading');

        const otpPayload = {
            sid: event.target.sid.value,
            otp: event.target.otp.value
        }

        axios.post(`${baseURL}${endpoints.verifyOtp}`, generatePayload(otpPayload))
            .then(response => {
                if (response.data.status) {
                    const token = response.data.token;

                    localStorage.setItem("JWToken", token);
                    localStorage.setItem("JWUid", response.data.uid);

                    if (response.data.regd) {
                        setAuthToken(token);
                        window.location.href = '/';
                    } else {
                        navigate({pathname: '/register'});
                    }

                    updateVotpButtonState('success');
                    setOtpError({ status: false, msg: '' });

                    updateGotpButtonState('default');
                    setMobileError({ status: false, msg: '' });
                }
                else {
                    setOtpError({ status: true, msg: response.data.msg });
                    updateVotpButtonState('error');
                }
            })
            .catch(error => console.log(error));
    };

    const verifyPassword = ( event ) => {
        event.preventDefault();
        event.currentTarget.blur();
        updatePwordButtonState('loading');

        const pwordPayload = {
            uid: event.target.uid.value,
            cred: event.target.cred.value
        }

        axios.post(`${baseURL}${endpoints.verifyPassword}`, generatePayload(pwordPayload))
            .then(response => {
                if (response.data.status) {
                    const token = response.data.token;

                    localStorage.setItem("JWToken", token);
                    localStorage.setItem("JWUid", response.data.uid);

                    if (response.data.regd) {
                        setAuthToken(token);
                        window.location.href = '/';
                    } else {
                        navigate({pathname: '/register'});
                    }

                    setPwordError({ status: false, msg: '' });
                    updatePwordButtonState('success');
                }
                else {
                    setPwordError({ status: true, msg: response.data.msg });
                    updatePwordButtonState('error');
                }
            })
            .catch(error => console.log(error));

        console.log(pwordPayload);
    };

    const switchLoginType = (state) => {
        updatePwordButtonState('default');
        setPwordError({ status: false, msg: '' });

        updateGotpButtonState('default');
        setMobileError({ status: false, msg: '' });

        updateVotpButtonState('default');
        setOtpError({ status: false, msg: '' });

        setActiveLoginType(state);
    };

    const switchOtpLoginView = (view) => {
        switch (view) {
            case 'init':
                toggleOtpLoginView(false);
                // setMobile('');
                break;

            case 'generate':
                // toggleOtpLoginView(true);
                // generateOTP();
                break;

            default:
                break;
        }
    };

    const hookLines = [
        { "title": "", "caption": "" , "img": "images/slides/illustration-slide-2.jpg"},
        { "title": "", "caption": "" , "img": "images/slides/illustration-slide-4.jpg"},
        { "title": "", "caption": "" , "img": "images/slides/illustration-slide-3.jpg"}
    ];

    const slides = hookLines.map((slide) => {
        return (
            <div className={ styles.ll_login_slide }>
                <img className={ styles.ll_login_bg_img } src={slide.img} alt={styles.title}></img>
                {/* <div className={styles.ll_login_slide_wrap}>
                    <h4 className={styles.ll_login_slide_title_wrap}>
                        <span className={styles.ll_logon_title_span}>
                            <span className={styles.ll_logon_title_innerspan_1}>{slide.title}</span>
                            <span className={styles.ll_logon_title_innerspan_2}>{slide.title}</span>
                        </span>
                    </h4>
                    <p className={styles.ll_login_slide_caption}>{slide.caption}</p>
                </div> */}
            </div>
        );
    });

    const slideConfig = {
        arrows: false,
        infinite: true,
        mobileFirst: true,
        autoplay: true,
        fade: true,
        cssEase: 'cubic-bezier(0.25, 1, 0.5, 1)',
        speed: 2000,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1023,
                settings: "unslick"
            }
        ]
    };

    return (
        <section className={styles.ll_login}>
            <div className={styles.ll_login_bg}></div>
            <div className={themes.ll_container}>
                <div className={styles.ll_login_wrap}>
                    <div className={styles.ll_login_bgwrap}>
                        <Slider {...slideConfig} className='flex w-full justify-center items-center'>
                            {slides}
                        </Slider>
                        {/* <img className={styles.ll_login_img} src='https://meyda.education.gov.il/files/pop/15683/e-learnn.jpg'></img> */}
                    </div>
                    <div className={styles.ll_login_form}>
                        <div className={styles.ll_login_brand}>
                            <img src={BrandLogo}></img>
                        </div>
                        <div className={styles.ll_login_titlewrap}>
                            <h3 className={styles.ll_login_title}>
                                <span>வணக்கம்</span>
                                { welcomeMessage
                                    ? <span className='font-semibold inline pl-1'>{welcomeMessage}</span>
                                    : <></>
                                }
                            </h3>
                            <span className={styles.ll_login_caption}></span>
                        </div>
                        <div className="w-full">
                            { activeLoginType === 'otpLogin'
                                ? <div className="w-full">
                                    <form className={`${styles.ll_login_formgroup} ${ !otpLoginView ? styles.active_form : styles.inactive_form }`} onSubmit={ generateOTP }>
                                        <label className={styles.ll_login_helptext} htmlFor="mobile">உங்கள் கைபேசி எண்ணை உள்ளிடவும்</label>
                                        <div className={styles.ll_login_formgroup}>
                                            <input type='tel' id="mobile" name="mobile" placeholder="கைபேசி எண்"
                                                    className={` ${styles.ll_forminput} ${styles.login_input} ${ mobileError.status ? styles.ll_error_field : '' }`}/>
                                            { mobileError.status ? <label className={styles.ll_login_errortext}> { mobileError.msg } </label> : '' }
                                        </div>
                                        <ButtonPrimary label='OTP பெறவும்' key={gotpButtonState} state={gotpButtonState}></ButtonPrimary>
                                        <button className={styles.btn_secondary} onClick={ () => switchLoginType('pwordLogin') }>
                                            <span className={styles.btn_label}>கடவுச்சொல் மூலம் உள்நுழைக</span>
                                        </button>
                                    </form>
                                    <form className={`${styles.ll_login_formgroup} ${ otpLoginView ? styles.active_form : styles.inactive_form }`}
                                        onSubmit={ verifyOTP }>
                                        <label className={styles.ll_login_helptext} htmlFor="password">உங்கள் 4 இலக்க OTP-ஐ உள்ளிடவும்</label>
                                        <div className={styles.ll_login_formgroup}>
                                            <input className={styles.ll_login_hiddenInput} placeholder="ஒரு முறை கடவுச்சொல் (OTP)" type='text' id="sid" name="sid" value={sid}/>
                                            <input className={` ${styles.ll_forminput} ${styles.login_input} ${ otpError.status ? styles.ll_error_field : '' }`} placeholder="ஒரு முறை கடவுச்சொல் (OTP)" type='tel' id="otp" name="otp"/>
                                            { otpError.status ? <label className={styles.ll_login_errortext}> { otpError.msg } </label> : '' }
                                        </div>
                                        <ButtonPrimary label='உள் நுழையவும்' key={votpButtonState} state={votpButtonState}></ButtonPrimary>
                                        <button className={styles.btn_secondary} onClick={ () => switchOtpLoginView('init') }>
                                            <span className={styles.btn_label}>பின் செல்ல</span>
                                        </button>
                                    </form>
                                </div>
                                : <></>
                            }
                            { activeLoginType === 'pwordLogin'
                                ? <div className="w-full">
                                    <form className={`${styles.ll_login_formgroup}`}
                                        onSubmit={ verifyPassword }>
                                        {/* <label className={styles.ll_login_helptext} htmlFor="pword_uid">மின்னஞ்சல் முகவரியை உள்ளிடவும்</label> */}
                                        <div className={styles.ll_login_formgroup}>
                                            <input className={` ${styles.ll_forminput} ${styles.login_input} ${ pwordError.status ? styles.ll_error_field : '' }`} placeholder="மின்னஞ்சல் முகவரி (அல்லது) கைபேசி எண்" type='tel' id="uid" name="uid"/>
                                            { pwordError.status ? <label className={styles.ll_login_errortext}> { pwordError.msg } </label> : '' }
                                            <input className={` ${styles.ll_forminput} ${styles.login_input} ${ pwordError.status ? styles.ll_error_field : '' } mt-4`} placeholder="கடவுச்சொல்" type='password' id="cred" name="cred"/>
                                            { pwordError.status ? <label className={styles.ll_login_errortext}> { pwordError.msg } </label> : '' }
                                        </div>
                                        <ButtonPrimary
                                            label='உள்நுழை'
                                            key={pwordButtonState}
                                            state={pwordButtonState}
                                        ></ButtonPrimary>
                                    </form>
                                    <button className={`${styles.btn_secondary} mb-2`} onClick={ () => switchLoginType('otpLogin') }>
                                        {/* <div className={styles.btn_icon_wrap}>
                                            <Hash></Hash>
                                        </div> */}
                                        <span className={styles.btn_label}>OTP மூலம் உள்நுழைக</span>
                                    </button>
                                    <button className={styles.btn_link} onClick={ () => navigate({pathname: '/passwordreset'}) }>
                                        {/* <div className={styles.btn_icon_wrap}>
                                            <Hash></Hash>
                                        </div> */}
                                        <span className={styles.btn_label}>கடவுச்சொல்லை மறந்துவிட்டீர்களா?</span>
                                    </button>
                                </div>
                                : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;