import { useState } from 'react';
import Select from 'react-select';

import styles from '../styles/login.module.scss';
import secStyles from '../styles/profile.module.scss'
import ButtonPrimary from './partials/buttonPrimary';

const ProfileOthersForm = (props) => {
    const [ saveButtonState, updateSaveButtonState ] = useState('default');

    const standard = [
        { value: "தமிழ்நாடு", label: "தமிழ்நாடு" },
        { value: "அந்தமான் & நிக்கோபார்", label: "அந்தமான் & நிக்கோபார்" },
        { value: "ஆந்திரப் பிரதேசம்", label: "ஆந்திரப் பிரதேசம்" },
        { value: "அருணாசலப் பிரதேசம்", label: "அருணாசலப் பிரதேசம்" },
        { value: "அஸ்ஸாம்", label: "அஸ்ஸாம்" },
        { value: "பீகார்", label: "பீகார்" },
        { value: "சட்டிஸ்கர்", label: "சட்டிஸ்கர்" },
        { value: "சண்டிகார் ", label: "சண்டிகார் " },
        { value: "டாமன் &டையு", label: "டாமன் &டையு" },
        { value: "தாதர் & நாகர் ஹாவேலி", label: "தாதர் & நாகர் ஹாவேலி" },
        { value: "டெல்லி", label: "டெல்லி" },
        { value: "கோவா", label: "கோவா" },
        { value: "குஜராத்", label: "குஜராத்" },
        { value: "ஹரியானா", label: "ஹரியானா" },
        { value: "ஹிமாசல பிரதேசம்", label: "ஹிமாசல பிரதேசம்" },
        { value: "ஜம்மு & காஷ்மீர்", label: "ஜம்மு & காஷ்மீர்" },
        { value: "ஜார்கண்ட்", label: "ஜார்கண்ட்" },
        { value: "லடாக்", label: "லடாக்" },
        { value: "லட்சத்தீவு", label: "லட்சத்தீவு" },
        { value: "கர்நாடகா", label: "கர்நாடகா" },
        { value: "கேரளா", label: "கேரளா" },
        { value: "மத்திய பிரதேசம்", label: "மத்திய பிரதேசம்" },
        { value: "மகாராஷ்டிரா", label: "மகாராஷ்டிரா" },
        { value: "மணிப்பூர்", label: "மணிப்பூர்" },
        { value: "மேகாலயா", label: "மேகாலயா" },
        { value: "மிசோராம்", label: "மிசோராம்" },
        { value: "நாகலாந்து", label: "நாகலாந்து" },
        { value: "ஒடிஸா", label: "ஒடிஸா" },
        { value: "புதுச்சேரி", label: "புதுச்சேரி" },
        { value: "பஞ்சாப்", label: "பஞ்சாப்" },
        { value: "ராஜஸ்தாந்", label: "ராஜஸ்தாந்" },
        { value: "சிக்கிம்", label: "சிக்கிம்" },
        { value: "தெலங்கானா", label: "தெலங்கானா" },
        { value: "திரிபுரா", label: "திரிபுரா" },
        { value: "உத்தரப்பிரதேசம்", label: "உத்தரப்பிரதேசம்" },
        { value: "உத்தரகாண்ட்", label: "உத்தரகாண்ட்" },
        { value: "மேற்கு வங்கம்", label: "மேற்கு வங்கம்" }
    ];
    
    const group = [
        { value: "Ariyalur", label: "Ariyalur" },
        { value: "Chengalpattu", label: "Chengalpattu" },
        { value: "Chennai", label: "Chennai" },
        { value: "Coimbatore", label: "Coimbatore" },
        { value: "Cuddalore", label: "Cuddalore" },
        { value: "Dharmapuri", label: "Dharmapuri" },
        { value: "Dindigul", label: "Dindigul" },
        { value: "Erode", label: "Erode" },
        { value: "Kallakurichi", label: "Kallakurichi" },
        { value: "Kanchipuram", label: "Kanchipuram" },
        { value: "Kanyakumari", label: "Kanyakumari" },
        { value: "Karur", label: "Karur" },
        { value: "Krishnagiri", label: "Krishnagiri" },
        { value: "Madurai", label: "Madurai" },
        { value: "Nagapattinam", label: "Nagapattinam" },
        { value: "Namakkal", label: "Namakkal" },
        { value: "Nilgiris", label: "Nilgiris" },
        { value: "Perambalur", label: "Perambalur" },
        { value: "Pudukkottai", label: "Pudukkottai" },
        { value: "Ramanathapuram", label: "Ramanathapuram" },
        { value: "Ranipet", label: "Ranipet" },
        { value: "Salem", label: "Salem" },
        { value: "Sivaganga", label: "Sivaganga" },
        { value: "Tenkasi", label: "Tenkasi" },
        { value: "Thanjavur", label: "Thanjavur" },
        { value: "Theni", label: "Theni" },
        { value: "Thoothukudi (Tuticorin)", label: "Thoothukudi (Tuticorin)" },
        { value: "Tiruchirappalli", label: "Tiruchirappalli" },
        { value: "Tirunelveli", label: "Tirunelveli" },
        { value: "Tirupathur", label: "Tirupathur" },
        { value: "Tiruppur", label: "Tiruppur" },
        { value: "Tiruvallur", label: "Tiruvallur" },
        { value: "Tiruvannamalai", label: "Tiruvannamalai" },
        { value: "Tiruvarur", label: "Tiruvarur" },
        { value: "Vellore", label: "Vellore" },
        { value: "Viluppuram", label: "Viluppuram" },
        { value: "Virudhunagar", label: "Virudhunagar" }
    ];      

    const customStyles = {
        valueContainer: (provided, state) => ({
            ...provided,
            padding: '0 4px 0 16px',
            marginTop: '-2px'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            fontSize: '12px',
            fontWeight: '500',
        }),
        menuList: (provided, state) => ({
            ...provided,
            fontSize: '14px',
            fontWeight: '500',
            zIndex: '20'
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: '20'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            fontWeight: '600',
            color: '#111827',
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            backgroundColor: state.isSelected ? '#6F42C1' : ( state.isFocused ? '#ebe0ff' : '' )
        }),
        input: (provided, state) => ({
            ...provided,
            fontSize: '10px',
            fontWeight: '100',
            outline: 'none'

        }),
        control: (provided, state) => ({
            ...provided,
            boxShadow: 'none',
            outline: 'none',
            borderColor: state.isSelected ? '#6F42C1' : ( state.isFocused ? '#6F42C1' : '' ),
            minHeight: '30px',
            maxHeight: '36px'
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#6F42C1' : ( state.isFocused ? '#6F42C1' : '#CCCCCC' ),
        })
    };

    const SaveBasicDetails = (e) => {
        e.preventDefault();

        updateSaveButtonState('loading');

        setTimeout(() => updateSaveButtonState('success'), 3000);
    };

    return (
        <div className={secStyles.ll_profile}>
            <div className={`${styles.ll_login} ${secStyles.ll_profile_form}`}>
                <form className={`${styles.ll_login_formgroup}`}>
                    <div className={`${styles.ll_login_titlewrap} ${secStyles.ll_titlewrap}`}>
                        <h3 className={`${styles.ll_login_title} ${secStyles.ll_profile_form_title}`}>இதர விவரங்கள்</h3>
                        <span className={styles.ll_login_caption}></span>
                    </div>
                    <div className={styles.ll_login_formgroup}>
                        <label className={`${styles.ll_form_basic_label} ${secStyles.ll_form_basic_label}`}>பெயர் - ஆங்கிலத்தில்</label>
                        <input
                            className={` ${styles.ll_forminput} ${styles.register_input}`}
                            placeholder="பெயர் - ஆங்கிலத்தில்"
                            type='text'
                            id="otp"
                            name="userEmail"
                        />
                    </div>
                    <div className={`${styles.ll_login_formgroup} ${styles.ll_login_formgroup_double}`}>
                        <div className={ `${styles.selectWrapper} ${styles.selectWrapper_md}` }>
                            <label className={`${styles.ll_form_basic_label} ${secStyles.ll_form_basic_label}`}>மாநிலம்</label>
                            <Select 
                                isSearchable={false} 
                                options={ standard } 
                                styles={ customStyles } 
                                placeholder="மாநிலம்">
                            </Select>
                        </div>
                        <div className={ `${styles.selectWrapper} ${styles.selectWrapper_md}`  }>
                            <label className={`${styles.ll_form_basic_label} ${secStyles.ll_form_basic_label}`}>நகரம்</label>
                            <Select 
                                isSearchable={false} 
                                options={ group } 
                                styles={customStyles} 
                                placeholder="நகரம்">
                            </Select>
                        </div>
                    </div>
                    <div className={styles.ll_login_formgroup}>
                        <label className={`${styles.ll_form_basic_label} ${secStyles.ll_form_basic_label}`}>பள்ளியின் பெயர்</label>
                        <input
                            className={` ${styles.ll_forminput} ${styles.register_input}`}
                            placeholder="பள்ளியின் பெயர்"
                            type='text'
                            id="otp"
                            name="userEmail"
                        />
                    </div>
                    <div className={styles.ll_login_formgroup}>
                        <label className={`${styles.ll_form_basic_label} ${secStyles.ll_form_basic_label}`}>பள்ளியின் முகவரி</label>
                        <textarea
                            className={` ${styles.ll_forminput} ${styles.register_input}`}
                            placeholder="பள்ளியின் முகவரி"
                            type='text'
                            id="otp"
                            name="userEmail"
                        />
                    </div>
                    <div className={styles.ll_login_formgroup}>
                        <label className={`${styles.ll_form_basic_label} ${secStyles.ll_form_basic_label}`}>அஞ்சல் குறியீடு</label>
                        <input
                            className={` ${styles.ll_forminput} ${styles.register_input}`}
                            placeholder="அஞ்சல் குறியீடு"
                            type='text'
                            id="otp"
                            name="userEmail"
                        />
                    </div>
                    <div className='w-full mt-4'>
                        <ButtonPrimary
                            label='தரவுகளை சேமிக்கவும்'
                            key={saveButtonState}
                            state={saveButtonState}
                            onClick={SaveBasicDetails}
                        ></ButtonPrimary>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ProfileOthersForm;