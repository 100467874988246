const NotificationOverlay = () => {
    const toggleNotificationsBar = (event) => {
        event.preventDefault()

        const bodyNode = document.body;
        const headerNode = document.getElementById('ll_notification_instance');

        bodyNode.classList.toggle('notifications-overlay-active')
        headerNode.classList.toggle('active')
    };

    return (
        <div className="notifications-overlay-wrap" onClick={toggleNotificationsBar}>
            <div className="notifications-overlay"></div>
            {/* <button className="close-btn fixed right-1 top-1 w-14 h-14 z-50 p-4 opacity-100">
                <img className="w-full h-full" src="images/icons/close-icon.png" alt="close overlay"></img>
            </button>   */}
        </div>
    );
}

export default NotificationOverlay;