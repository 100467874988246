import { useEffect, useState } from 'react';
import styles from '../../styles/login.module.scss';

import tickMark from '../../static/tickMark.png';
import recycleIcon from '../../static/recycle.png';
import rightArrow from '../../static/rightArrow.png';

const ButtonPrimary = (props) => {
    const [ buttonState, updateButtonState ] = useState(props.state);
    const [ activeClass, updateActiveClass ] = useState(styles[`buttonState_${buttonState}`]);
    const [ activeLabel, updateActiveLabel ] = useState(props.label);
    const [ activeIcon, updateActiveIcon] = useState(rightArrow);

    useEffect(() => {
        console.log(activeClass);

        if (buttonState === 'loading') {
            updateActiveLabel('ஏற்றப்படுகிறது');
        } else if (buttonState === 'success') {
            updateActiveLabel('முழுமை அடைந்தது');
            updateActiveIcon(tickMark);
        } else if (buttonState === 'error') {
            updateActiveLabel('மீண்டும் முயற்சிக்கவும்');
            updateActiveIcon(recycleIcon);
        } else {
            updateButtonState('default');
        }
    }, [activeClass]);

    useEffect(() => {
        updateActiveClass(styles[`buttonState_${buttonState}`]);
    }, [ buttonState ]);

    console.log();

    return (
        <button
            className={`${styles.ll_login_btn} ${styles.btn_primary} ${activeClass}`}
            onClick={props.onClick}
        >
            <span className={styles.btn_label}>{activeLabel}</span>
            {
                activeIcon
                ? <img className={styles.buttonState_icon} src={activeIcon} alt="activeLabel"></img>
                : <></>
            }
        </button>
    );
}

export default ButtonPrimary;