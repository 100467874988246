import axios from "axios";

export const setAuthToken = token => {
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        localStorage.setItem('JWToken', token);
    }
    else {
        delete axios.defaults.headers.common["Authorization"];
        localStorage.setItem('JWToken', false);
        window.location.href = "/login";
    }
}