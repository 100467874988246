import Slider from "react-slick";

import theme from '../styles/theme.module.scss'
import styles from '../styles/adBannerRollingVideos.module.scss'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const AdBannerRollingVideos = (props) => {

    console.log('Rolling slides', props);

    const slideConfig = {
        autoplay: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 5000,
        arrows: false,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        cssEase: 'cubic-bezier(0.76, 0, 0.24, 1)',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
                {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
                {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
      ]
    };

    const promotedContentSlides = props.context.map((slide) => {
        const actionImage = slide.itemType === 'video' ? '/images/icons/play-icon.png' : '/images/icons/ext-link.png';

        return (
            <div className={styles.ll_promoted_slide}>
                <div className={styles.ll_slide_wrap}>
                    <a href={slide.href} className={styles.ll_hyperlink} target="new" alt={slide.title}></a>
                    <div className={styles.ll_thumbnail}>
                        <img href={slide.href} className={styles.ll_action_icon} src={actionImage} alt={slide.title}></img>
                        <img className={styles.ll_slide_img} src={slide.image} alt={slide.title}></img>
                    </div>
                    <div className={styles.ll_info}>
                        <img src={slide.icon} className={styles.ll_icon}></img>
                        <h4 className={styles.ll_title}>{slide.title}</h4>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <section className={`${styles.ll_rolling_videos} ll_rolling_videos`}>
            <div className={`${theme.ll_container} ${styles.ll_carousel_container}`}>
                <div className='flex w-full'>
                    <h3 className='text-lg lg:text-xl font-semibold text-left mb-4 px-4 lg:px-0'>{props.title}</h3>
                </div>
                <Slider {...slideConfig} className={styles.ll_horizontal_slider}>
                    {promotedContentSlides}
                </Slider>
            </div>
        </section>
    );
}

export default AdBannerRollingVideos;