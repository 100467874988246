import React from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";

import Home from "../pages/home";
import Login from "../pages/login";
import LoginOptions from "../pages/loginoptions";
import About from "../pages/about";
import Profile from "../pages/profile";
import Register from "../pages/register";
import SubjectListing from "../pages/subjectListing";
import TopicListing from "../pages/topicListing";
import NotFound from "../pages/NotFound";
import VideoPlayer from "../pages/videoLanding";
import VideoPlayerView from "../pages/videoLanding";
import Library from "../pages/library";
import Bookmarks from "../pages/bookmarks";
import Notifications from "../pages/notifications";
import Rewards from "../pages/rewards";
import Reports from "../pages/reports";
import Assessments from "../pages/assessments";
import Requests from "../pages/requests";
import Info from "../pages/info";
import Events from "../pages/events";
import PasswordReset from "../pages/passwordReset";

const Routers = () => {
    const hasJWT = () => {
        let flag = false;

        localStorage.getItem("JWToken") && localStorage.getItem("JWToken").token !== 'false'
            ? flag = true
            : flag = false;
    
        return flag;
    }

    return (
        // <BrowserRouter>
            <Routes>
                {/* Default landing page */}
                <Route path="/" element={ hasJWT() ? <Home/> : <Navigate to="/login" /> }></Route>
                <Route path="/home" element={ hasJWT() ? <Home/> : <Navigate to="/login" /> }></Route>

                {/* Account related pages */}
                <Route exact path="/login" element={ <Login/> }></Route>
                <Route exact path="/loginoptions" element={ <LoginOptions/> }></Route>
                <Route exact path="/register" element={ <Register/> }></Route>
                <Route exact path="/passwordreset" element={ <PasswordReset/> }></Route>
                <Route exact path="/profile" element={ hasJWT() ? <Profile/> : <Navigate to="/login" /> }></Route>

                {/* Context Based Pages */}
                <Route exact path="/library" element={ hasJWT() ? <Library/> : <Navigate to="/login" /> }></Route>
                <Route exact path="/bookmarks" element={ hasJWT() ? <Bookmarks/> : <Navigate to="/login" /> }></Route>
                <Route exact path="/subject" element={ hasJWT() ? <SubjectListing/> : <Navigate to="/login" /> }></Route>
                <Route exact path="/:subjectCode" element={ hasJWT() ? <SubjectListing/> : <Navigate to="/login" /> }></Route>
                <Route exact path="/:subjectCode/:unitCode" element={ hasJWT() ? <TopicListing/> : <Navigate to="/login" /> }></Route>
                <Route exact path="/:subjectCode/:unitCode/:topicCode" element={ hasJWT() ? <TopicListing/> : <Navigate to="/login" /> }></Route>
                <Route exact path="/:subjectCode/:unitCode/:topicCode/:videoId" element={ hasJWT() ? <VideoPlayerView/> : <Navigate to="/login" /> }></Route>

                {/* Functional Based Pages */}
                <Route exact path="/notifications" element={ hasJWT() ? <Notifications/> : <Navigate to="/login" /> }></Route>
                <Route exact path="/rewards" element={ hasJWT() ? <Rewards/> : <Navigate to="/login" /> }></Route>
                <Route exact path="/quiz" element={ hasJWT() ? <Assessments/> : <Navigate to="/login" /> }></Route>
                <Route exact path="/reports" element={ hasJWT() ? <Reports/> : <Navigate to="/login" /> }></Route>
                <Route exact path="/requests" element={ hasJWT() ? <Requests/> : <Navigate to="/login" /> }></Route>
                <Route exact path="/events" element={ hasJWT() ? <Events/> : <Navigate to="/login" /> }></Route>

                {/* Info Pages */}
                <Route exact path="/info/:contentType" element={ hasJWT() ? <Info/> : <Navigate to="/login" /> }></Route>

                {/* Fallback Pages */}
                <Route exact path="/notfound" element={ hasJWT() ? <NotFound/> : <Navigate to="/login" /> }></Route>
                <Route exact path="*" element={ hasJWT() ? <NotFound/> : <Navigate to="/login" /> }></Route>
            </Routes>
        // </BrowserRouter>
    );
}

export default Routers;