import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import PageLayout from "../layouts/pageLayout";
import RollingSlides from "../components/rollingSlides";
import HeroCards from "../components/heroCards";
import VideoTiles from "../components/videoTiles";
import { baseURL } from "../App";
import endpoints from '../data/api.json'
import UpdateUserContext from "../helpers/updateUserContext";
import Loader from "../components/loader";
import ActionableItems from "../components/actionableItems";
import Breadcrumbs from "../components/breadcrumbs";
import Fallback from "../components/fallbackPage";
import generatePayload from "../helpers/generatePayload";
import { CoreContext } from "../config/context";
import StatCards from "../components/statCards";
import BarChartVertical from "../components/charts/barChartVertical";
import AreaChartVertical from "../components/charts/areaChartVertical";
import PieChartInstance from "../components/charts/pieChartInstance";
import PieGroupLayout from "../layouts/pieGroupLayout";
import DataGrid from "../components/dataGrid";

const Reports = () => {
    const globals = useContext(CoreContext);

    const [ compContext, setContext ] = useState({});
    const [ loading, setLoading ] = useState(false);

    const payload = {
        uid: localStorage.getItem('JWUid'),
        sid: localStorage.getItem('JWToken')
    };

    const crumbs = [
        { name: 'முகப்பு', path: '/' },
        { name: 'அறிக்கைகள்', path : `/reports` }
    ];

    const sampleData = {
        metrics: {
            video: {
                value: 123,
                label: 'Videos watched',
                theme: 'blue'
            },
            chapters: {
                value: 41,
                label: 'Chapters completed',
                theme: 'orange'
            },
            quiz: {
                value: 36,
                label: 'Quizzes taken',
                theme: 'yellow'
            },
            test: {
                value: 11,
                label: 'Tests attempted',
                theme: 'green'
            },
        }
    };

    const videoWatchData = [
        { Day: '14-Feb', Value: 70 },
        { Day: '15-Feb', Value: 75 },
        { Day: '16-Feb', Value: 55 },
        { Day: '17-Feb', Value: 75 },
        { Day: '18-Feb', Value: 50 },
        { Day: '19-Feb', Value: 72.5 },
        { Day: '20-Feb', Value: 100 },
        { Day: '21-Feb ', Value: 80 }
    ];

    const subjectVideoData = [
        { name: 'Physics', value: 46 },
        { name: 'Chemistry', value: 29 },
        { name: 'Mathematics', value: 20 },
        { name: 'Botany', value: 16 },
        { name: 'Zoology', value: 10 },
    ];

    const subjectQuizData = [
        { name: 'Physics', value: 20 },
        { name: 'Chemistry', value: 35 },
        { name: 'Mathematics', value: 10 },
        { name: 'Botany', value: 6 },
        { name: 'Zoology', value: 4 },
    ];

    const subjectTestData = [
        { name: 'Physics', value: 10 },
        { name: 'Chemistry', value: 5 },
        { name: 'Mathematics', value: 6 },
        { name: 'Botany', value: 9 },
        { name: 'Zoology', value: 7 },
    ];

    const userActivity = [
        {
            id: 'abc123',
            title: "பாரடேயின் மின் காந்தத்தூண்டல் சோதனைகள்",
            type: "video",
            subject: "இயற்பியல்",
            timestamp: "Nov 21, 2022 at 3:33 PM"
        },
        {
            id: 'abc4563',
            title: "பூச்சியமற்ற கோவை அணியின் நேர்மாறு",
            type: "quiz",
            subject: "கணிதம்",
            timestamp: "Dec 2, 2022 at 10:03 AM"
        },
        {
            id: 'abc45611',
            title: "புவிஈர்ப்புமுறை அல்லது ஓடும் நீரில் கழுவுதல்",
            type: "video",
            subject: "வேதியியல்",
            timestamp: "Dec 2, 2022 at 10:03 AM"
        },
        {
            id: 'abc789',
            title: "உலோகவியல் - தாதுக்களை அடர்பித்தல்",
            type: "video",
            subject: "வேதியியல்",
            timestamp: "Dec 5, 2022 at 5:36 PM"
        },
        {
            id: 'abc45611122',
            title: "மின்காந்தத்தூண்டலும் மாறுதிசை மின்னோட்டமும்",
            type: "test",
            subject: "கணிதம்",
            timestamp: "Dec 10, 2022 at 10:03 AM"
        },
        {
            id: 'abc4563',
            title: "புவிஈர்ப்புமுறை அல்லது ஓடும் நீரில் கழுவுதல்",
            type: "quiz",
            subject: "வேதியியல்",
            timestamp: "Dec 12, 2022 at 10:03 AM"
        },
        {
            id: 'abc45611',
            title: "பூச்சியமற்ற கோவை அணியின் நேர்மாறு",
            type: "video",
            subject: "கணிதம்",
            timestamp: "Dec 15, 2022 at 10:03 AM"
        }
    ];
    

    const fetchData = () => {

        const payload = {
            uid: localStorage.getItem('JWUid'),
            sid: localStorage.getItem('JWToken')
        };

        axios.post(`${baseURL}${endpoints.fetchLibrary}`, generatePayload(payload))
            .then(response => {
                const token = response.data.token;

                if (!token) {
                    window.location.href = '/login';
                } else {
                    setContext(response.data);

                    globals.CoreDispatch({
                        udata: response.data.udata,
                        theme: globals.CoreState['theme']
                    });

                    setLoading(true);
                }

                localStorage.setItem("JWToken", token);

                setAuthToken(token);

                document.title = `தமிழ் - 📈 அறிக்கைகள்`;
            })
            .catch(error => {
                console.log(error)
                setAuthToken(false);
            });
    };

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <PageLayout>
            { loading?
                (
                    <>
                        <Breadcrumbs context={crumbs} />
                        <AreaChartVertical context={videoWatchData}></AreaChartVertical>
                        <StatCards context={sampleData.metrics}></StatCards>
                        <PieGroupLayout>
                            <PieChartInstance title='காணொளி விவரம்:' context={subjectVideoData} key={0}></PieChartInstance>
                            <PieChartInstance title='quiz விவரம்:' context={subjectQuizData} key={1}></PieChartInstance>
                            <PieChartInstance title='தேர்வு விவரம்:' context={subjectTestData} key={2}></PieChartInstance>
                        </PieGroupLayout>
                        <DataGrid title="பயனர் செயல்பாடு:" context={userActivity}></DataGrid>
                    </>
                )
                :
                (
                    <Loader></Loader>
                )
            }
        </PageLayout>
    );
}

export default Reports;