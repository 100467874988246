import styles from '../styles/subjectIndex.module.scss'
import ImageTiles from '../components/imageTiles';

const SubjectIndex = (props) => {

    const unitsData = units => {

        return units.map((unit) => {

            return (
                <li key={unit.unitCode} className={styles.ll_unit_group}>
                    <ul className="flex">
                        <ImageTiles order={unit.unitNo} title={unit.displayName} context={unit.topics}></ImageTiles>
                    </ul>
                </li>
            );
        });
    };

    return (
        <div className={styles.ll_subject_index}>
            <div className='flex w-full'>
                <h3 className='text-lg lg:text-xl font-semibold px-4 text-left'>"<span className='text-llprimary'>{props.title}</span>" - க்கான உள்ளடக்கங்களைக் காட்டுகிறது</h3>
            </div>
            <ul>
                {unitsData(Object.values(props.context))}
            </ul>
        </div>
    );
}

export default SubjectIndex;