import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { RouterContext } from '../App';

import theme from '../styles/theme.module.scss'
import styles from '../styles/breadcrumbs.module.scss'

import Backward from '../components/svgs/backward'
import Forward from '../components/svgs/forward'

const Breadcrumbs = (props) => {
    const fullCrumbs = props.context.map((route, count) => {
        return (
            <li className={styles.ll_full_crumb}>
                <Backward css={styles.left}/>
                <Link className='leading-5' to={route.path}>{route.name}</Link>
                <Forward css={styles.right}/>
            </li>
        );
    });

    return (
        <section className={styles.ll_breadcrumbs}>
            <div className={theme.ll_container}>
                <div className={styles.ll_full_breadcrumb_wrap}>
                    <ul className={styles.ll_full_breadcrumb}>
                        { fullCrumbs }
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Breadcrumbs;