import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import generatePayload from "../helpers/generatePayload";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

import styles from '../styles/login.module.scss'
import themes from '../styles/theme.module.scss'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BrandLogo from '../static/ll_tamizh_logo.svg'
import Key from "../components/svgs/key";
import Hash from "../components/svgs/hash";
import { CoreContext } from "../config/context";
import ButtonPrimary from "../components/partials/buttonPrimary";

const PasswordReset = () => {
    const navigate = useNavigate();
    const globals = useContext(CoreContext);

    const [ compContext, setContext ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ gotpButtonState, updateGotpButtonState ] = useState('default');
    const [ formState, setFormState ] = useState('enterPassword');

    const payload = {
        uid: localStorage.getItem('JWUid'),
        sid: localStorage.getItem('JWToken')
    };

    const submitPassword = () => {
        setFormState('enterOtp');
    };

    const verifyOtp = () => {
        navigate({pathname: '/login'})
    };

    const hookLines = [
        { "title": "", "caption": "" , "img": "images/slides/illustration-slide-2.jpg"},
        { "title": "", "caption": "" , "img": "images/slides/illustration-slide-4.jpg"},
        { "title": "", "caption": "" , "img": "images/slides/illustration-slide-3.jpg"}
    ];

    const crumbs = [
        { name: 'முகப்பு', path: '/' },
        { name: 'கடவுச்சொல் மீட்டமைப்பு', path : `/passwordreset` }
    ];

    const slides = hookLines.map((slide) => {
        return (
            <div className={ styles.ll_login_slide }>
                <img className={ styles.ll_login_bg_img } src={slide.img} alt={styles.title}></img>
                {/* <div className={styles.ll_login_slide_wrap}>
                    <h4 className={styles.ll_login_slide_title_wrap}>
                        <span className={styles.ll_logon_title_span}>
                            <span className={styles.ll_logon_title_innerspan_1}>{slide.title}</span>
                            <span className={styles.ll_logon_title_innerspan_2}>{slide.title}</span>
                        </span>
                    </h4>
                    <p className={styles.ll_login_slide_caption}>{slide.caption}</p>
                </div> */}
            </div>
        );
    });

    const slideConfig = {
        arrows: false,
        infinite: true,
        mobileFirst: true,
        autoplay: true,
        fade: true,
        cssEase: 'cubic-bezier(0.25, 1, 0.5, 1)',
        speed: 2000,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1023,
                settings: "unslick"
            }
        ]
    };

    return (
        <section className={styles.ll_login}>
            <div className={styles.ll_login_bg}></div>
            <div className={themes.ll_container}>
                <div className={styles.ll_login_wrap}>
                    <div className={styles.ll_login_bgwrap}>
                        <Slider {...slideConfig} className='flex w-full justify-center items-center'>
                            {slides}
                        </Slider>
                        {/* <img className={styles.ll_login_img} src='https://meyda.education.gov.il/files/pop/15683/e-learnn.jpg'></img> */}
                    </div>
                    <div className={styles.ll_login_form}>
                        <div className={styles.ll_login_brand}>
                            <img src={BrandLogo}></img>
                        </div>
                        <div className={styles.ll_login_titlewrap}>
                            <h3 className={styles.ll_login_title}>
                                <span>கடவுச்சொல்லை மீட்டமைக்க</span>
                            </h3>
                            <span className={styles.ll_login_caption}></span>
                        </div>
                        <div className="w-full">
                            {
                                formState === "enterPassword"
                                ? <form className={`${styles.ll_login_formgroup}`} onSubmit={ submitPassword }>
                                    <label className={styles.ll_login_helptext} htmlFor="mobile">உங்கள் கணக்கு விவரங்களை உள்ளிடவும்</label>
                                    <div className={styles.ll_login_formgroup}>
                                        <input type='tel' id="mobile" name="mobile" placeholder="மின்னஞ்சல் முகவரி (அல்லது) கைபேசி எண்"
                                                className={` ${styles.ll_forminput} ${styles.login_input}`}/>
                                    </div>
                                    <div className={styles.ll_login_formgroup}>
                                        <input type='password' id="pword" name="pword" placeholder="கடவுச்சொல்லை உள்ளிடவும்"
                                                className={` ${styles.ll_forminput} ${styles.login_input}`}/>
                                    </div>
                                    <div className={styles.ll_login_formgroup}>
                                        <input type='password' id="confirmPword" name="confirmPword" placeholder="கடவுச்சொல்லை உறுதிப்படுத்தவும்"
                                                className={` ${styles.ll_forminput} ${styles.login_input}`}/>
                                    </div>
                                    <ButtonPrimary label='விவரங்களை சமர்ப்பிக்கவும்' key={gotpButtonState} state={gotpButtonState}></ButtonPrimary>
                                    <button className={styles.btn_secondary} onClick={ () => navigate({pathname: '/login'}) }>
                                        <span className={styles.btn_label}>உள்நுழைய செல்லவும்</span>
                                    </button>
                                </form>
                                : <></>
                            }
                            {
                                formState === "enterOtp"
                                ? <form className={`${styles.ll_login_formgroup}`} onSubmit={ verifyOtp }>
                                    <div className={styles.ll_login_formgroup}>
                                        <input className={styles.ll_login_hiddenInput} type='text' id="sid" name="sid"/>
                                    </div>
                                    <div className={styles.ll_login_formgroup}>
                                        <input type='password' id="confirmPword" name="confirmPword" placeholder="ஒரு முறை கடவுச்சொல் (OTP)"
                                                className={` ${styles.ll_forminput} ${styles.login_input}`}/>
                                    </div>
                                    <ButtonPrimary label='OTP-ஐ சரிபார்க்கவும்' key={gotpButtonState} state={gotpButtonState}></ButtonPrimary>
                                    <button className={styles.btn_secondary} onClick={ () => setFormState('enterPassword') }>
                                        <span className={styles.btn_label}>பின் செல்ல</span>
                                    </button>
                                </form>
                                : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PasswordReset;