import theme from '../styles/theme.module.scss'
import styles from '../styles/videoPlayer.module.scss'
import { setAuthToken } from "../helpers/authToken";

import JWPlayerInstance from './jwPlayerInstance';
// import ShakaPlayerInstance from './shakaPlayerInstance';

import BookMark from './svgs/bookmark';
import Like from './svgs/like';
import Dislike  from './svgs/dislike';
import Share  from './svgs/share';
import More  from './svgs/more';
import Eye  from './svgs/eye';
import Backward from './svgs/backward'
import { Link, useLocation, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import axios from 'axios';
import api from '../data/api.json'
import { baseURL } from '../App';
import endpoints from '../data/api.json'
import generatePayload from '../helpers/generatePayload';
import { CoreContext } from '../config/context';

const VideoPlayer = (props) => {
    const { pathname } = useLocation();
    const playerContext = props.playerContext;
    const globals = useContext(CoreContext);

    const { subjectCode, unitCode, topicCode } = useParams();

    const minicrumbs = {
        subject: `/${subjectCode}`,
        topic: `/${subjectCode}/${unitCode}/${topicCode}`
    };

    const [ videoBookmark, updateVideoBookmark ] = useState(playerContext.saved);
    const [ videoLike, updateVideoLike ] = useState({ liked: playerContext.social.liked, disliked: playerContext.social.disliked });

    const [ socialApiLock, updateSocialApiLock ] = useState(false);
    const [ bookmarkApiLock, updateBookmarkApiLock ] = useState(false);

    const socialUpdate = (e, action) => {
        e.stopPropagation();
        updateSocialApiLock(true);

        updateVideoLike({ liked: action === 'like', disliked: action === 'dislike' });

        e.currentTarget.blur();
    };

    useEffect(() => {
        const payload = {
            uid: localStorage.getItem("JWUid"),
            sid: localStorage.getItem("JWToken"),
            videoId: playerContext.videoID,
            std: globals.CoreState.udata.ugrade,
            groupCode: globals.CoreState.udata.ucontextid,
            subjectCode: playerContext.subjectID,
            subTopicCode: playerContext.subTopicID,
            like: videoLike.liked,
            dislike: videoLike.disliked
        };

        console.log(payload);

        if ( socialApiLock ) {
            axios.post(`${baseURL}${endpoints.likeVideo}`, generatePayload(payload))
                .then(response => {
                    if (response.data.status) {
                        updateVideoBookmark({
                            liked: response.data.socials.liked,
                            disliked: response.data.socials.disliked
                        });
                    }
                })
                .catch( error => {
                    console.log(error);
                });
        }
    }, [ videoLike ]);

    const socialBookmark = (e) => {
        e.stopPropagation();
        updateBookmarkApiLock(true);
        updateVideoBookmark(!videoBookmark);
        // e.currentTarget.classList.toggle(styles.ll_active_icon);

        const payload = {
            uid: localStorage.getItem("JWUid"),
            sid: localStorage.getItem("JWToken"),
            videoId: playerContext.videoID,
            std: globals.CoreState.udata.ugrade,
            groupCode: globals.CoreState.udata.ucontextid,
            subjectCode: playerContext.subjectID,
            subTopicCode: playerContext.subTopicID,
            action: !videoBookmark ? 'save' : 'remove'
        };

        axios.post(`${baseURL}${endpoints.bookmarkVideo}`, generatePayload(payload))
            .then(response => {
                if (response.data.status) { updateVideoBookmark(response.data.socials.bookmark); }
            })
            .catch( error => {
                console.log(error);
            });

        e.currentTarget.blur();
    };

    useEffect(() => {
        console.log('videoBookmark After Change', videoBookmark);

        if ( bookmarkApiLock ) {
            updateBookmarkApiLock(false);
        }
    }, [ videoBookmark ]);

    useEffect(() => {
        console.log('on URL change', props.playerContext.videoID, ' is ', props.playerContext.social.saved);
        updateVideoBookmark(props.playerContext.social.saved);
    }, [ pathname ]);

    return (
        <section className={styles.ll_video_player}>
            <div className={theme.ll_container}>
                <div className={styles.ll_comp_wrapper}>
                    <div className={`${theme.ll_aspectratio_wrap} ${styles.ll_player_video_wrapper}`}>
                        <JWPlayerInstance context={props.playerContext}></JWPlayerInstance>
                        {/* <FluidPlayerInstance context={props.playerContext}></FluidPlayerInstance> */}
                        {/* <ShakaPlayerInstance context={props.playerContext}></ShakaPlayerInstance> */}
                    </div>
                    <div className={styles.ll_player_info_wrapper}>
                        <div className={styles.ll_player_info_contents}>
                            <div className='flex flex-col lg:flex-col-reverse justify-start items-start text-left'>
                                <div className='flex flex-row-reverse'>                               
                                    <Link to={minicrumbs.topic} className='text-llprimary font-semibold whitespace-nowrap leading-none px-2 mt-3 mb-0 flex items-start lg:mb-4 lg:mt-2 lg:text-base ll_before_dot lg:pr-2' >
                                        <Backward css='flex lg:hidden'/>
                                        <span className='flex leading-5 lg:ml-2'>{playerContext.unitName}</span>
                                    </Link>
                                    <Link to={minicrumbs.subject} className='text-llprimary font-semibold whitespace-nowrap leading-none px-2 mt-3 mb-0 hidden lg:flex items-start lg:mb-4 lg:mt-2 lg:text-base lg:pl-4' >
                                        <Backward css='hidden'/>
                                        <span className='flex leading-5'>{playerContext.subjectName}</span>
                                    </Link>     
                            </div>
                                <h5 className='text-xl font-semibold text-gray-800 leading-7 multi-line-ellipsis mx-2 px-2 mt-1 lg:mt-4 lg:mb-2 lg:text-lg'>{props.playerContext.videoTitle}</h5>
                            </div>
                            <p className={playerContext.viewed ? `${styles.ll_video_info_sec} ${styles.ll_video_info_active} flex lg:hidden` : `${styles.ll_video_info_sec} flex lg:hidden` }>
                                <Eye css='w-4 h-6 mr-1 mt-1px leading-5'></Eye>
                                <span className='mr-1 whitespace-nowrap leading-none flex items-center'>
                                    <span className='text-sm'>{playerContext.views}</span>
                                    <span className='text-base ml-1'>பார்வைகள்</span>
                                </span>
                            </p>
                            <div className='w-full'>
                                <div className={styles.ll_player_socialbar}>
                                    <p className={playerContext.viewed ? `${styles.ll_video_info_sec} ${styles.ll_video_info_active} hidden lg:flex lg:pt-2` : `${styles.ll_video_info_sec} hidden lg:flex` }>
                                        <Eye css='w-4 h-6 mr-1 mt-1px leading-5'></Eye>
                                        <span className='mr-1 whitespace-nowrap leading-none flex items-center'>
                                            <span className='text-sm'>{playerContext.views}</span>
                                            <span className='text-base ml-1'>பார்வைகள்</span>
                                        </span>
                                    </p>
                                    <button 
                                        onClick={(e) => socialUpdate(e, 'like')}
                                        socialAction='like'
                                        className={ videoLike.liked ? `${styles.ll_social_icon} ${styles.ll_active_icon}` : `${styles.ll_social_icon}` }>
                                        <Like></Like>
                                    </button>
                                    <button
                                        onClick={(e) => socialUpdate(e, 'dislike')}
                                        socialAction='dislike'
                                        className={ videoLike.disliked ? `${styles.ll_social_icon} ${styles.ll_active_icon}` : `${styles.ll_social_icon}` }>
                                        <Dislike></Dislike>
                                    </button>
                                    <button className={ styles.ll_social_icon }>
                                        <Share></Share>
                                    </button>
                                    <button
                                        onClick={ socialBookmark }
                                        className={ videoBookmark ? `${styles.ll_social_icon} ${styles.ll_active_icon}` : `${styles.ll_social_icon}` }
                                        disabled={bookmarkApiLock}>
                                        <BookMark></BookMark>
                                    </button>
                                    <button className={ styles.ll_social_icon }>
                                        <More></More>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default VideoPlayer;