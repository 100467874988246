import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export let fromNavlink = false;

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const removeOverlays = () => {
    const bodyNode = document.body;
    const headerNode = document.getElementById('ll_sidebar_instance');

    if (bodyNode && bodyNode.classList && headerNode && headerNode.classList) {
      bodyNode.classList.remove('overlay-active')
      headerNode.classList.remove('active')
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    removeOverlays();
  }, [pathname]);

  fromNavlink = true;

  return null;
}