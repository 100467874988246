import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import PageLayout from "../layouts/pageLayout";
import Breadcrumbs from "../components/breadcrumbs";
import VideoGrids from "../components/videoGrid";
import VideoPlayer from "../components/videoPlayer";
import endpoints from '../data/api.json';
import { baseURL } from "../App";
import Loader from "../components/loader";
import generatePayload from "../helpers/generatePayload";
import { useLocation, useParams } from "react-router-dom";
import VideoTiles from "../components/videoTiles";
import { CoreContext } from "../config/context";

const VideoPlayerView = () => {
    const globals = useContext(CoreContext);

    const [ compContext, setContext ] = useState({});
    const [ crumbs, setCrumbs ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const { pathname } = useLocation();

    const { subjectCode, unitCode, topicCode, videoId } = useParams();

    const payload = {
        uid: localStorage.getItem('JWUid'),
        sid: localStorage.getItem('JWToken'),
        videoID: videoId
    };

    const fetchData = () => {
        axios.post(`${baseURL}${endpoints.videoDetails}`, generatePayload(payload))
            .then(response => {
                const token = response.data.token;

                if (!token) {
                    window.location.href = '/login';
                } else {
                    setContext(response.data);

                    globals.CoreDispatch({
                        udata: response.data.udata,
                        theme: globals.CoreState['theme']
                    });

                    setLoading(true);
                }

                localStorage.setItem("JWToken", token);

                setAuthToken(token);

                document.title = `தமிழ் - ⏯️ ${response.data.ucontext.videoTitle}`;
            })
            .catch(error => {
                console.log(error)
                setAuthToken(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, [pathname]);


    return (
        <PageLayout>
            { loading?
                (
                    <>
                        <VideoPlayer playerContext={compContext.ucontext}></VideoPlayer>
                        <VideoTiles variant="progressIndicator" title="அடுத்து பார்க்க" showCta="false" context={compContext.playNext}></VideoTiles>
                    </>
                )
                :
                (
                    <Loader></Loader>
                )
            }
        </PageLayout>
    );
}

export default VideoPlayerView;