import theme from '../styles/theme.module.scss';
import styles from '../styles/actionableItems.module.scss';
import { Link } from 'react-router-dom';

const ActionableItems = (props) => {

    const messages = Object.values(props.context.messages).map((item) => {

        const classList = item.status === 'new'
                        ? `${styles.ll_message_item} ${styles.ll_new_msg}`
                        : `${styles.ll_message_item}`;

        const categoryStyle = `ll_category_${item.category}`;

        return (
            <li className={ `${classList} ${categoryStyle}` }>
                <Link className={ styles.ll_message_wrap } to={ item.actionUrl }>
                    <div className={ styles.ll_img_wrap }>
                        <img className={ styles.ll_avatar } src={ item.avatar } alt={ item.title }></img>
                    </div>
                    <div className={ styles.ll_message_content }>
                        <h4 className={styles.ll_message_title}>
                            <span className={styles.ll_title_main}>{ item.title }</span>
                            { item.suffix ? <span className={styles.ll_title_caption}>- { item.suffix } </span> : '' }
                        </h4>
                        <p className={ `${ styles.ll_message_desc } multi-line-ellipsis` }>{ item.description }</p>
                    </div>
                </Link>
            </li>
        );
    });

    return (
        <section className={ styles.ll_actionable_items }>
            <div className={theme.ll_container}>
                <div class={styles.ll_wrapper}>
                    <h3 className={styles.ll_h3}>
                        {props.title}
                        <span className={styles.ll_h3_sub}>
                            (
                                <span className={styles.ll_unread_count}>
                                    { `${ (Object.values(props.context.messages).filter((item) => item.status === "new")).length }` }
                                </span>
                            )
                        </span>
                    </h3>
                </div>
                <div>
                    <ul className={ styles.ll_message }>
                        { messages }
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default ActionableItems;