import styles from '../styles/sidebarMenu.module.scss'

import { Link, NavLink } from "react-router-dom";

import BrandLogo from '../static/ll_tamizh_logo.svg'
import Home from './svgs/home'
import UserIcon from './svgs/user';
import BookMark from './svgs/bookmark'
import Award from './svgs/award';
import Library from './svgs/library';
import Reports from './svgs/reports';
import Quiz from './svgs/quiz';
import Requests from './svgs/requests';
import Settings from './svgs/settings'
import Logout from './svgs/logout'
import Moon from './svgs/moon'
import LifeSaver from './svgs/lifesaver'
import Info from './svgs/info'
import Share from './svgs/share'
import More from './svgs/more';
import Calendar from './svgs/calendar';

const SidebarMenu = (props) => {

    const userContext = props.context;

    const sidebarMenu = [
        { name: "Home", image: "user.svg",  href: "./"},
        { name: "Reports", image: "",  href: "./"},
        { name: "Profile", image: "",  href: "./"},
        { name: "Bookmarks", image: "",  href: "./"},
        { name: "Library", image: "",  href: "./"},
        { name: "Rewards", image: "",  href: "./"},
        { name: "Privacy policy", image: "",  href: "./"},
        { name: "Settings", image: "",  href: "./"}

    ];
    const listItems = sidebarMenu.map((item) => {
        return (
            <li className={styles.ll_sidebar_item}>
                <img src={`image/icons/${item.image}`} alt={item.name}></img>
                {item.name}
            </li>
        );
    });

    return (
        <div id="ll_sidebar_instance" className={`${styles.ll_sidebar} ll_sidebar`}>
            <div className={styles.ll_sidebar_menu}>
                <div className={styles.ll_sidebar_top}>
                    <Link to={`/`}>
                        <div className={styles.ll_brand_header}>
                            <img src={BrandLogo} className={styles.ll_brand_logo_main} alt="Learn Like"></img>
                            <h1 className="sr-only">LearnLike</h1>
                            <h2 className={styles.ll_main_nav_legend}>தள பக்கங்கள்</h2>
                        </div>
                    </Link>
                    <div className={styles.ll_sidebar_items}>
                        <li className={styles.ll_sidebar_item}>
                            <NavLink to={`/`} className={(navData) => navData.isActive ? `${styles.ll_sidebar_link} ${styles.active}` : styles.ll_sidebar_link }>
                                <Home></Home>
                                <span className={styles.ll_sidebar_item_label}>முகப்பு</span>
                            </NavLink>
                        </li>
                        {/* <li className={styles.ll_sidebar_item}>
                            <NavLink to={`/profile`} className={(navData) => navData.isActive ? `${styles.ll_sidebar_link} ${styles.active}` : styles.ll_sidebar_link }>
                                <UserIcon></UserIcon>
                                <span className={styles.ll_sidebar_item_label}>சுயவிவரம்</span>
                            </NavLink>
                        </li> */}
                        <li className={styles.ll_sidebar_item}>
                            <NavLink to={`/bookmarks`} className={(navData) => navData.isActive ? `${styles.ll_sidebar_link} ${styles.active}` : styles.ll_sidebar_link }>
                                <BookMark></BookMark>
                                <span className={styles.ll_sidebar_item_label}>புத்தககுறிகள்</span>
                            </NavLink>
                        </li>
                        <li className={styles.ll_sidebar_item}>
                            <NavLink to={`/rewards`} className={(navData) => navData.isActive ? `${styles.ll_sidebar_link} ${styles.active}` : styles.ll_sidebar_link }>
                                <Award></Award>
                                <span className={styles.ll_sidebar_item_label}>வெகுமதிகள்</span>
                            </NavLink>
                        </li>
                        <li className={styles.ll_sidebar_item}>
                            <NavLink to={`/library`} className={(navData) => navData.isActive ? `${styles.ll_sidebar_link} ${styles.active}` : styles.ll_sidebar_link }>
                                <Library></Library>
                                <span className={styles.ll_sidebar_item_label}>தொகுப்புகள்</span>
                            </NavLink>
                        </li>
                        <li className={styles.ll_sidebar_item}>
                            <NavLink to={`/quiz`} className={(navData) => navData.isActive ? `${styles.ll_sidebar_link} ${styles.active}` : styles.ll_sidebar_link }>
                                <Quiz></Quiz>
                                <span className={styles.ll_sidebar_item_label}>தேர்வுகள்</span>
                            </NavLink>
                        </li>
                        <li className={styles.ll_sidebar_item}>
                            <NavLink to={`/events`} className={(navData) => navData.isActive ? `${styles.ll_sidebar_link} ${styles.active}` : styles.ll_sidebar_link }>
                                <Calendar></Calendar>
                                <span className={styles.ll_sidebar_item_label}>நிகழ்வுகள்</span>
                            </NavLink>
                        </li>
                        <li className={styles.ll_sidebar_item}>
                            <NavLink to={`/reports`} className={(navData) => navData.isActive ? `${styles.ll_sidebar_link} ${styles.active}` : styles.ll_sidebar_link }>
                                <Reports></Reports>
                                <span className={styles.ll_sidebar_item_label}>அறிக்கைகள்</span>
                            </NavLink>
                        </li>
                        <li className={styles.ll_sidebar_item}>
                            <NavLink to={`/requests`} className={(navData) => navData.isActive ? `${styles.ll_sidebar_link} ${styles.active}` : styles.ll_sidebar_link }>
                                <Requests/>
                                <span className={styles.ll_sidebar_item_label}>கோரிக்கைகள்</span>
                            </NavLink>
                        </li>
                        {/* <li className={styles.ll_sidebar_item}>
                            <NavLink to={`/settings`} className={(navData) => navData.isActive ? `${styles.ll_sidebar_link} ${styles.active}` : styles.ll_sidebar_link }>
                                <Settings></Settings>
                                <span className={styles.ll_sidebar_item_label}>அமைப்புகள்</span>
                            </NavLink>
                        </li>
                        <li className={styles.ll_sidebar_item}>
                            <NavLink to={`/logout`} className={(navData) => navData.isActive ? `${styles.ll_sidebar_link} ${styles.active}` : styles.ll_sidebar_link }>
                                <Logout></Logout>
                                <span className={styles.ll_sidebar_item_label}>வெளியேறு</span>
                            </NavLink>
                        </li> */}
                    </div>
                </div>
                <div className={styles.ll_sidebar_bottom}>
                    <div className={styles.ll_sidebar_footer}>
                        <img src={BrandLogo} className={styles.ll_brand_logo} alt="Learn Like"></img>
                        <span className="text-gray-400">© KIT Plus LearnLike's Tamizh 2021.</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SidebarMenu;