import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import PageLayout from "../layouts/pageLayout";
import RollingSlides from "../components/rollingSlides";
import HeroCards from "../components/heroCards";
import VideoTiles from "../components/videoTiles";
import { baseURL } from "../App";
import endpoints from '../data/api.json'
import UpdateUserContext from "../helpers/updateUserContext";
import Loader from "../components/loader";
import ActionableItems from "../components/actionableItems";
import Breadcrumbs from "../components/breadcrumbs";
import Fallback from "../components/fallbackPage";
import generatePayload from "../helpers/generatePayload";
import { CoreContext } from "../config/context";

const Assessments = () => {
    const globals = useContext(CoreContext);

    const [ compContext, setContext ] = useState({});
    const [ loading, setLoading ] = useState(false);

    const payload = {
        uid: localStorage.getItem('JWUid'),
        sid: localStorage.getItem('JWToken')
    };

    const crumbs = [
        { name: 'முகப்பு', path: '/' },
        { name: 'தேர்வுகள்', path : `/assessments` }
    ];

    const fetchData = () => {

        axios.post(`${baseURL}${endpoints.assessmentsLanding}`, generatePayload(payload))
            .then(response => {
                const token = response.data.token;

                if (!token) {
                    window.location.href = '/login';
                } else {
                    setContext(response.data);
                    
                    globals.CoreDispatch({
                        udata: response.data.udata,
                        theme: globals.CoreState['theme']
                    });

                    setLoading(true);
                }

                localStorage.setItem("JWToken", token);

                setAuthToken(token);

                document.title = `தமிழ் - 📝 தேர்வுகள்`;
            })
            .catch(error => {
                console.log(error)
                setAuthToken(false);
            });
    };

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <PageLayout>
            { loading?
                (
                    <>
                        <Breadcrumbs context={crumbs} />
                        <Fallback
                            title="📝 தேர்வுகள்"
                            image="https://user-images.githubusercontent.com/65872394/123756467-5010c300-d893-11eb-9916-596d3302ce98.gif"
                            message="இந்த அம்சத்தை விரைவில் தொடங்க உள்ளோம், தயவுசெய்து காத்திருங்கள்!"
                            redirect=""
                        />
                    </>
                )
                :
                (
                    <Loader></Loader>
                )
            }
        </PageLayout>
    );
}

export default Assessments;