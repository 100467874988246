import { useState } from 'react';
import styles from '../styles/productCards.module.scss';
import Cart from './svgs/cart';
import Heart from './svgs/heart';

const ProductCards = (props) => {
    const cards = props.context.map((item) => {
        const btn = (value) => {
            return (
                <button className={styles.ll_value_btn}>
                    <span className=''>{value}</span>
                    <Cart></Cart>
                </button>
            );
        };

        let liked = item.filter.split(',').includes('wishlist');

        return (
            <li className={styles.ll_product_card}>
                <div className={styles.ll_product_img_wrap}>
                    <div className={`${styles.ll_svg_wrap} ${ liked ? styles.liked : '' }`}
                        onClick={ () => { liked = true; } }>
                        <Heart></Heart>
                    </div>
                    <img className={styles.ll_product_img} src={item.image} alt={item.title}></img>
                </div>
                <div className={styles.ll_product_info}>
                    <span className={styles.ll_product_name}>{item.name}</span>
                    <span className={styles.ll_product_desc}>{item.desc}</span>
                    {btn(item.value)}
                    {/* <span className={styles.ll_product_value}>{item.value}</span> */}
                </div>
            </li>
        );
    });

    return (
        <ul className={styles.ll_product_cards}>{cards}</ul>
    );
};

export default ProductCards;