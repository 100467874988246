import { useState } from 'react';
import Select from 'react-select';

import styles from '../styles/login.module.scss';
import secStyles from '../styles/profile.module.scss'
import ButtonPrimary from './partials/buttonPrimary';

const ProfileBasicForm = (props) => {
    const [ saveButtonState, updateSaveButtonState ] = useState('default');

    const standard = [
        { value: '9', label: '9th' },
        { value: '10', label: '10th' },
        { value: '11', label: '11th' },
        { value: '12', label: '12th' }
    ];
    
    const group = [
        { value: 'G103', label: 'உயிரியல்- இயற்பியல் - வேதியியல் - கணிதம்' },
        { value: 'G102', label: 'கணினி அறிவியல் - இயற்பியல் - வேதியியல் - கணிதம்' },
        { value: 'G208', label: 'தாவரவியல் - விலங்கியல் - இயற்பியல் - வேதியியல்' }
    ];      

    const customStyles = {
        valueContainer: (provided, state) => ({
            ...provided,
            padding: '0 4px 0 16px',
            marginTop: '-2px'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            fontSize: '12px',
            fontWeight: '500',
        }),
        menuList: (provided, state) => ({
            ...provided,
            fontSize: '14px',
            fontWeight: '500',
            zIndex: '20'
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: '20'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            fontWeight: '600',
            color: '#111827',
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            backgroundColor: state.isSelected ? '#6F42C1' : ( state.isFocused ? '#ebe0ff' : '' )
        }),
        input: (provided, state) => ({
            ...provided,
            fontSize: '10px',
            fontWeight: '100',
            outline: 'none'

        }),
        control: (provided, state) => ({
            ...provided,
            boxShadow: 'none',
            outline: 'none',
            borderColor: state.isSelected ? '#6F42C1' : ( state.isFocused ? '#6F42C1' : '' ),
            minHeight: '30px',
            maxHeight: '36px'
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#6F42C1' : ( state.isFocused ? '#6F42C1' : '#CCCCCC' ),
        })
    };

    const SaveBasicDetails = (e) => {
        e.preventDefault();

        updateSaveButtonState('loading');

        setTimeout(() => updateSaveButtonState('success'), 3000);
    };

    return (
        <div className={secStyles.ll_profile}>
            <div className={`${styles.ll_login} ${secStyles.ll_profile_form}`}>
                <form className={`${styles.ll_login_formgroup}`}>
                    <div className={`${styles.ll_login_titlewrap} ${secStyles.ll_titlewrap}`}>
                        <h3 className={`${styles.ll_login_title} ${secStyles.ll_profile_form_title}`}>முதன்மை விவரங்கள்</h3>
                        <span className={styles.ll_login_caption}></span>
                    </div>
                    <div className={styles.ll_login_formgroup}>
                        <label className={`${styles.ll_form_basic_label} ${secStyles.ll_form_basic_label}`}>பெயர்</label>
                        <input
                            className={` ${styles.ll_forminput} ${styles.register_input}`}
                            placeholder="பெயர்"
                            type='text'
                            id="otp"
                            name="userEmail"
                        />
                    </div>
                    <div className={styles.ll_login_formgroup}>
                        <label className={`${styles.ll_form_basic_label} ${secStyles.ll_form_basic_label}`}>கைபேசி எண்</label>
                        <input
                            className={` ${styles.ll_forminput} ${styles.register_input}`}
                            placeholder="கைபேசி எண்"
                            type='text'
                            id="otp"
                            name="userEmail"
                        />
                    </div>
                    <div className={styles.ll_login_formgroup}>
                        <label className={`${styles.ll_form_basic_label} ${secStyles.ll_form_basic_label}`}>மின்னஞ்சல் முகவரி</label>
                        <input
                            className={` ${styles.ll_forminput} ${styles.register_input}`}
                            placeholder="மின்னஞ்சல் முகவரி"
                            type='text'
                            id="otp"
                            name="userEmail"
                        />
                    </div>
                    <div className={`${styles.ll_login_formgroup} ${styles.ll_login_formgroup_double}`}>
                        <div className={ `${styles.selectWrapper} ${styles.selectWrapper_sm}` }>
                            <label className={`${styles.ll_form_basic_label} ${secStyles.ll_form_basic_label}`}>வகுப்பு</label>
                            <Select 
                                isSearchable={false} 
                                options={ standard } 
                                styles={ customStyles } 
                                placeholder="வகுப்பு">
                            </Select>
                        </div>
                        <div className={ `${styles.selectWrapper} ${styles.selectWrapper_lg}`  }>
                            <label className={`${styles.ll_form_basic_label} ${secStyles.ll_form_basic_label}`}>பிரிவு</label>
                            <Select 
                                isSearchable={false} 
                                options={ group } 
                                styles={customStyles} 
                                placeholder="பிரிவு">
                            </Select>
                        </div>
                    </div>
                    <div className={`${styles.ll_login_formgroup} ${styles.ll_login_formgroup_double} ${styles.ll_login_formgroup_btnGrp}`}>
                        <label className={`${styles.ll_form_basic_label} ${secStyles.ll_form_basic_label}`}>பயனர் வகை</label>
                        <div className={styles.ll_radiogroup}>
                            <div className={styles.ll_radioitem}>
                                <label className={ styles.ll_radio_label }>
                                    <input class={styles.ll_radio_btn}
                                        type="radio"
                                        value="student"
                                    />
                                    <span>மாணவர்</span>
                                </label>
                            </div>
                            <div className={styles.ll_radioitem}>
                                <label className={ styles.ll_radio_label }>
                                    <input class={styles.ll_radio_btn}
                                        type="radio"
                                        value="teacher"
                                    />
                                    <span>ஆசிரியர்</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.ll_login_formgroup} ${styles.ll_login_formgroup_double} ${styles.ll_login_formgroup_btnGrp}`}>
                        <label className={`${styles.ll_form_basic_label} ${secStyles.ll_form_basic_label}`}>பாலினம்</label>
                        <div className={styles.ll_radiogroup}>
                            <div className={styles.ll_radioitem}>
                                <label className={ styles.ll_radio_label }>
                                    <input class={styles.ll_radio_btn}
                                        type="radio"
                                        value="male"
                                    ></input>
                                    <span>ஆண்</span>
                                </label>
                            </div>
                            <div className={styles.ll_radioitem}>
                                <label className={ styles.ll_radio_label }>
                                    <input class={styles.ll_radio_btn}
                                        type="radio"
                                        value="female"
                                    ></input>
                                    <span>பெண்</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='w-full mt-4'>
                        <ButtonPrimary
                            label='தரவுகளை சேமிக்கவும்'
                            key={saveButtonState}
                            state={saveButtonState}
                            onClick={SaveBasicDetails}
                        ></ButtonPrimary>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ProfileBasicForm;