import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import Nav from "../components/nav";
import PageLayout from "../layouts/pageLayout";
import Breadcrumbs from "../components/breadcrumbs";
import SubjectIndex from "../components/subjectIndex";
import { baseURL } from "../App";
import endpoints from '../data/api.json';
import { useParams } from "react-router-dom";
import generatePayload from "../helpers/generatePayload";
import Loader from "../components/loader";
import { CoreContext } from "../config/context";

const SubjectListing = () => {
    const globals = useContext(CoreContext);

    const [ compContext, setContext ] = useState({});
    const [ crumbs, setCrumbs ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ content, setContent ] = useState({});

    const { subjectCode } = useParams();

    const fetchData = () => {
        const payload = {
            uid: localStorage.getItem('JWUid'),
            sid: localStorage.getItem('JWToken'),
            subjectID: subjectCode
        };

        axios.post(`${baseURL}${endpoints.fetchUnits}`, generatePayload(payload))
            .then(response => {
                const token = response.data.token;

                if (!token) {
                    window.location.href = '/login';
                } else {
                    if (!response.data.status || !response.data.content) {
                        window.location.href = '/notfound';
                    } else {
                        if (response.data.content) {
                            setContext(response.data.ucontext[subjectCode]);

                            globals.CoreDispatch({
                                udata: response.data.udata,
                                theme: globals.CoreState['theme']
                            });
    
                            setCrumbs([
                                { name: 'முகப்பு', path: '/' },
                                { name: response.data.ucontext[subjectCode].displayName, path : `/${response.data.ucontext[subjectCode].code}` }
                            ]);
                        }

                        setLoading(true);

                        localStorage.setItem("JWToken", token);

                        setAuthToken(token);

                        document.title = `தமிழ் - 📘 ${response.data.ucontext[subjectCode].displayName}`;
                    }
                }
            })
            .catch(error => {
                console.log(error)
                setAuthToken(false);
            });
    };

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <PageLayout>
            { loading ?
                (
                    <>
                        <Breadcrumbs context={crumbs}></Breadcrumbs>
                        <SubjectIndex title={compContext.displayName} context={compContext.units}></SubjectIndex>
                    </>
                )
                :
                (
                    <Loader></Loader>
                )
            }
        </PageLayout>
    );
}

export default SubjectListing;