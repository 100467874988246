import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import theme from '../../styles/theme.module.scss'

const AreaChartVertical = (props) => {

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const payloadObj = payload[0].payload;

            return (
                <div className="custom-tooltip bg-white border-2 border-purple-600 rounded-md px-4 py-3">
                    <p className="label block">
                        <span>You have watched</span>
                        <span className="font-bold">{` ${payloadObj.Value} hours `}</span>
                    </p>
                    <p className="label">
                        <span>of learning content on</span>
                        <span className="font-bold">{` ${payloadObj.Day} `}</span>
                    </p>
                </div>
            );
        }
        return null;
    };

    return (
        <section className="w-full mb-4 mb-8">
            <div className={theme.ll_container}>
                <div className="flex relative w-full bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className='flex w-full absolute left-4 top-3'>
                        <h3 className='text-lg lg:text-xl font-semibold text-left mb-4'>கற்றல் சுருக்கம்:</h3>
                    </div>
                    <ResponsiveContainer width="100%" height={200}>
                        <AreaChart data={props.context} width={150} height={100} margin={{top: 48}}>
                            <defs>
                                <linearGradient id="colorValue" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="0%" stopColor="#6f42c1" stopOpacity={1} />
                                    {/* <stop offset="80%" stopColor="#ae69d4" stopOpacity={0.8} /> */}
                                    <stop offset="100%" stopColor="#c577db" stopOpacity={1} />
                                </linearGradient>
                            </defs>
                            <Tooltip content={<CustomTooltip/>}/>
                            {/* <Legend verticalAlign="top" height={36}/> */}
                            {/* <XAxis dataKey="Day" fontFamily="sans-serif" fontSize={16}/> */}
                            {/* <YAxis dataKey="Value" fontFamily="sans-serif" fontSize={16}/> */}
                            <CartesianGrid vertical={false} stroke="#ebf3f0" strokeDashArray="3 3" />
                            <Area type="monotone" dataKey="Value" stroke="#a400ff" fill="url(#colorValue)" fillOpacity="1"/>
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </section>
    );
};

export default AreaChartVertical;