import React from 'react';
import { Link } from "react-router-dom";

import theme from '../styles/theme.module.scss'
import styles from '../styles/heroCards.module.scss'

const HeroCards = (props) => {
    console.log(props);
    const cards = Object.values(props.context).map((item) => {
        return (
            <li key={item.code} className={`${styles.ll_card} ${styles.ll_card_adjust}`}>
                <Link
                    className={`${ styles.ll_card_anchor } ${ item.active ? '' : styles.disabled }`}
                    key={item.code}
                    to={{
                        pathname: `/${item.code}`,
                        state: "item.code"
                    }}
                >
                    <img className={styles.ll_card_anchor_img} src={item.img} alt={item.name}></img>
                    <h5 className={`${styles.ll_card_anchor_title} flex-text`}>{item.name}</h5>
                </Link>
            </li>
        );
    });

    return (
        <section className={`${theme.ll_section} ${styles.ll_hero_cards}`}>
            <div className={theme.ll_container}>
                <div class={styles.ll_wrapper}>
                    <h3 className={styles.ll_h3}>
                        {props.title}
                        <span className={styles.ll_h3_sub}> (12 ஆம் வகுப்பு)</span>
                    </h3>
                    <ul className={styles.ll_cards}>
                        {cards}
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default HeroCards;