import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { setAuthToken } from "../helpers/authToken";
import PageLayout from "../layouts/pageLayout";
import RollingSlides from "../components/rollingSlides";
import HeroCards from "../components/heroCards";
import VideoTiles from "../components/videoTiles";
import { baseURL } from "../App";
import endpoints from '../data/api.json'
import UpdateUserContext from "../helpers/updateUserContext";
import Loader from "../components/loader";
import ActionableItems from "../components/actionableItems";
import Breadcrumbs from "../components/breadcrumbs";
import generatePayload from "../helpers/generatePayload";
import { CoreContext } from "../config/context";

const Notifications = () => {
    const globals = useContext(CoreContext);

    const [ compContext, setContext ] = useState({});
    const [ loading, setLoading ] = useState(false);

    const crumbs = [
        { name: 'முகப்பு', path: '/' },
        { name: 'அறிவிப்புகள்', path : `/notifications` }
    ];

    const fetchData = () => {
        const payload = {
            uid: localStorage.getItem('JWUid'),
            sid: localStorage.getItem('JWToken')
        };

        axios.post(`${baseURL}${endpoints.fetchNotifications}`, generatePayload(payload))
            .then(response => {
                const token = response.data.token;

                if (!token) {
                    window.location.href = '/login';
                } else {
                    setContext(response.data);
                    
                    globals.CoreDispatch({
                        udata: response.data.udata,
                        theme: globals.CoreState['theme']
                    });

                    setLoading(true);
                }

                localStorage.setItem("JWToken", token);

                setAuthToken(token);

                document.title = `தமிழ் - 📣 அறிவிப்புகள்`;
            })
            .catch(error => {
                console.log(error)
                setAuthToken(false);
            });
    };

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <PageLayout>
            { loading?
                (
                    <>
                        <Breadcrumbs context={crumbs} />
                        <ActionableItems title="உங்களுக்கான அறிவிப்புகள்" context={compContext.unotifications} />
                    </>
                )
                :
                (
                    <Loader></Loader>
                )
            }
        </PageLayout>
    );
}

export default Notifications;