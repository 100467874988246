import React, { useState } from "react";

const LoginOptions = () => {
    const [ activeLoginType, setActiveLoginType ] = useState('otpLogin');
    const [ optLoginState, setOtpLoginState ] = useState('init');
    const [ pwordLoginState, setPwordLoginState ] = useState('init');
    const [ requestOtpBtnState, setRequestOtpBtnState ] = useState('default');
    const [ verifyOtpBtnState, setVerifyOtpBtnState ] = useState('default');
    const [ verifyPasswordBtnState, setVerifyPasswordBtnState ] = useState('default');

    const switchLoginType = (state) => {
        setActiveLoginType(state);
        setOtpLoginState('init');
    };

    const switchOptLoginState = (state) => {
        setOtpLoginState(state);
    };

    return (
        <div class="flex w-full h-screen">
            <div class="flex w-1/4 items-center content-center m-auto">
                { ( activeLoginType === 'otpLogin')
                    ? <div class="view-1 flex w-full flex-col">
                        <h2 className="mb-4 text-left font-bold">Login with OTP</h2>
                        { (optLoginState === 'init')
                            ? <div>
                                <div className="mb-4">
                                    <label className="text-left" htmlFor="otp_uid"></label>
                                    <input  className="w-full rounded-sm border-2 border-gray-200 p-2"
                                            placeholder="Mobile" type="number" name="otp_uid" id="ll_otp_uid"></input>
                                </div>
                                <button onClick={ () => switchOptLoginState('verify') } className="w-full mb-4 rounded-sm bg-green-600 text-white p-2">Request OTP</button>
                            </div>
                            : <></>
                        }
                        { (optLoginState === 'verify' || optLoginState === 'submitted')
                            ? <div>
                                <div className="mb-4">
                                    <label className="text-left" htmlFor="otp"></label>
                                    <input  className="w-full rounded-sm border-2 border-gray-200 p-2"
                                            placeholder="OTP" type="number" name="otp" id="ll_otp"></input>
                                </div>
                                <button onClick={ () => switchOptLoginState('submitted') } className="w-full mb-4 rounded-sm bg-green-600 text-white p-2">Proceed</button>
                            </div>
                            : <></>
                        }
                        <button onClick={ () => switchLoginType('pwordLogin') } className="mb-4 rounded-sm border-2 border-blue-600 text-blue-600 p-2">Login with Password</button>
                    </div>
                    : <></>
                }
                { ( activeLoginType === 'passwordLogin')
                    ? <div class="view-2 flex w-full flex-col">
                        <h2 className="mb-4 text-left font-bold">Login with Password</h2>
                        <div className="mb-4">
                            <label htmlFor="pword_uid"></label>
                            <input  className="w-full rounded-sm border-2 border-gray-200 p-2"
                                    placeholder="Username" type="number" name="pword_uid" id="ll_pword_uid"></input>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="pword"></label>
                            <input  className="w-full rounded-sm border-2 border-gray-200 p-2"
                                    placeholder="Password" type="number" name="pword" id="ll_pword"></input>
                        </div>
                        <button className="mb-4 rounded-sm bg-green-600 text-white p-2">Proceed</button>
                        <button onClick={ () => switchLoginType('otpLogin') } className="mb-4 rounded-sm border-2 border-blue-600 text-blue-600 p-2">Login with OTP</button>
                        <button onClick={ () => switchLoginType('passwordLogin') } className="mb-4 underline border-gray-600 text-gray-600 p-2">Forgot password?</button>
                    </div>
                    : <></>
                }
                { ( activeLoginType === 'forgotPassword')
                    ? <div class="view-2 flex w-full flex-col">
                        <h2 className="mb-4 text-left font-bold">Login with Password</h2>
                        <div className="mb-4">
                            <label htmlFor="pword_uid"></label>
                            <input  className="w-full rounded-sm border-2 border-gray-200 p-2"
                                    placeholder="Username" type="number" name="pword_uid" id="ll_pword_uid"></input>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="pword"></label>
                            <input  className="w-full rounded-sm border-2 border-gray-200 p-2"
                                    placeholder="Password" type="number" name="pword" id="ll_pword"></input>
                        </div>
                        <button className="mb-4 rounded-sm bg-green-600 text-white p-2">Proceed</button>
                        <button onClick={ () => switchLoginType('otpLogin') } className="mb-4 rounded-sm border-2 border-blue-600 text-blue-600 p-2">Login with OTP</button>
                        <button onClick={ () => switchLoginType('passwordLogin') } className="mb-4 underline border-gray-600 text-gray-600 p-2">Forgot password?</button>
                    </div>
                    : <></>
                }
            </div>
        </div>
    );
};

export default LoginOptions;