import theme from '../styles/theme.module.scss';

const Fallback = (props) => {
    return (
        <section className={`flex w-full flex-col items-center justify-center lg:mb-8 ${ theme.ll_screenheight80 }`}>
            <h3 className="font-bold text-5xl px-4 mb-8 text-llprimary">{ props.title }</h3>
            <img className="w-150px float_image" src={ props.image } alt={ props.title }></img>
            <p className="text-3xl font-semibold text-gray-600 my-8">{ props.message }</p>
        </section>
    );
}

export default Fallback;