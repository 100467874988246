import theme from '../styles/theme.module.scss'
import styles from '../styles/videoGrids.module.scss'

import { Link } from "react-router-dom";

import playIcon from '../static/play-icon.png'

const VideoGrids = (props) => {
    const context = props.context;

    console.log('context', context);

    const videoThumbnails = Object.values(context.subtopics.videos).map((item) => {

        return (
            <Link to={`${item.videoID}`}
                    className={`${styles.ll_video_item} ${ item.active ? '' : styles.disabled } ll_video_item flex`}
                    key={item.videoId}>
                <div className={styles.ll_video_wrap}>
                    <div className={styles.ll_video_thumb}>
                        <img className={styles.ll_play_icon} src={playIcon} alt="play video"></img>
                        <img className={styles.ll_video_thumb_img} src={item.videoThumbnail} alt={item.videoTitle}></img>
                        <span className={styles.ll_video_thumb_time}>{item.durationLabel}</span>
                    </div>
                    <div className={styles.ll_video_info}>
                        <h5 className={`${styles.ll_video_title} multi-line-ellipsis`}>{item.videoTitle}</h5>
                        <p className={styles.ll_video_category}>{item.subjectName} <span className={styles.ll_video_subcategory}>• {item.unitName}</span></p>
                    </div>
                </div>
            </Link>
        );
    });

    return (
        <section className={styles.ll_video_grids}>
            <div className={theme.ll_container}>
                <div className={styles.ll_video_grids_wrapper}>
                    <div className={styles.ll_title_wrap}>
                        <h5 className={styles.ll_h3}>"<span className='text-llprimary'>{props.title}</span>" • க்கான உள்ளடக்கங்களைக் காட்டுகிறது</h5>
                    </div>
                    <div className={styles.ll_video_grid_wrap}>
                        {videoThumbnails}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default VideoGrids;