import React, { useContext } from 'react';

import theme from '../styles/theme.module.scss'

import MainContent from './mainContent';

import Header from '../components/header'
import SidebarMenu from '../components/sidebarMenu'
import NotificationBar from '../components/notificationBar'; 
import MainOverlay from '../layouts/mainOverlay';
import NotificationOverlay from '../layouts/notificationOverlay';
import Footer from '../components/footer'

const PageLayout = (props) => {
    return (
        <div className="common-content">
            <Header customClass="learnLike" title="LearnLike"></Header>
            <MainContent>
            <SidebarMenu context={{}}></SidebarMenu>
            <div className={theme.ll_app_body}>
                {props.children}
                <Footer></Footer>
            </div>
            </MainContent>
            <NotificationBar></NotificationBar>
            <MainOverlay></MainOverlay>
            <NotificationOverlay></NotificationOverlay>
        </div>
    );
}

export default PageLayout;