import { createContext } from 'react'

export const CoreContext = createContext({});

export const updateContext = (state, action) => {
    return action;
};



// import React, { useState, createContext } from 'react'

// export const UserContext = createContext({
//   User: "en",
//   setUser: () => {}
// })

// export const updateContext = (state, action) => {
//   return action;
// };

// export const UserContextProvider = (props) => {

//   const setUser = (User) => {
//     console.log('User',User);
//     setState({...state, User: User})
//   }

//   const initState = {
//     User: "en",
//     setUser: setUser
//   } 

//   const [state, setState] = useState(initState)

//   return (
//     <UserContext.Provider value={state}>
//       {props.children}
//     </UserContext.Provider>
//   )
// }