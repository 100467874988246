import theme from '../styles/theme.module.scss'
import styles from '../styles/videoList.module.scss'

import { Link } from "react-router-dom";
import { useReducer } from 'react';
import { useState } from 'react';

const VideoList = (props) => {
    const initialBookmarks = Object.values(props.context);
    const filterBookmarks = (state, action) => {
        return (action === 'all')
            ? initialBookmarks
            : Object.values(initialBookmarks).filter(item => item.subjectID === action );
    };

    let videoFilterContext = {};
    const [ activeItems, filterItems ] = useReducer(filterBookmarks, initialBookmarks);
    const [ activeFilterLink, setActiveFilterLink ] = useState('all');

    const filterVideos = (code) => {
        filterItems(code);
        setActiveFilterLink(code);
    };

    // eslint-disable-next-line array-callback-return
    Object.values(props.context).map((video) => {
        if (videoFilterContext[`${video.subjectID}`]) {
            videoFilterContext[`${video.subjectID}`] = {
                label: video.subjectName,
                code: video.subjectID,
                count: videoFilterContext[`${video.subjectID}`]['count'] + 1
            }
        } else {
            videoFilterContext[`${video.subjectID}`] = {
                label: video.subjectName,
                code: video.subjectID,
                count: 1
            };
        }
    });

    const subjectFilters = Object.values(videoFilterContext).map((video) => {
        return Object.values(videoFilterContext).length > 1
            ?   (
                    <li className={`${styles.ll_video_filter_item}  ${ activeFilterLink === video.code && styles.active_item}`}
                        subjectcode={ video.code }
                        count={ video.count }
                        onClick={() => filterVideos(video.code)}>
                            { video.label }
                    </li>
                )
            : <></>;
    });

    const videoItems = Object.values(activeItems).map((video) => {
        return (
            <Link to={`/${video.subjectID}/${video.unitID}/${video.topicID}/${video.videoID}`}
                className={`lg:mb-2 ${ video.active ? '' : styles.disabled }`}>
                <li className={`${styles.ll_video_list_item} ${styles.ll_video_list_item_fc} ${styles.ll_video_list_item_lc} ${styles.ll_video_list_item_bg}`}>
                    <div className={styles.ll_video_thumbnail_wrap}>
                        <img className={styles.ll_video_thumbnail} src={video.videoThumbnail} alt={video.videoTitle}></img>
                        <span className={styles.ll_video_timestamp}>{video.videoElapsedTime}</span>
                    </div>
                    <div className={styles.ll_video_info_wrap}>
                        <h5 className={`${styles.ll_video_title} multi-line-ellipsis`}>{video.videoTitle}</h5>
                        <p className={`${styles.ll_video_description} multi-line-ellipsis`}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <p className={styles.ll_video_category}>{video.subjectName} <span className={styles.ll_video_subcategory}>• {video.unitName}</span></p>
                    </div>
                </li>
            </Link>
        );
    });

    return ( 
        <section className={styles.ll_video_list}>
            <div className={`${theme.ll_container} ${styles.ll_list_container}`}>
                <div className={styles.ll_wrapper}>
                    <div className={styles.ll_title_wrap}>
                        <h3 className={styles.ll_h3}>{props.title}</h3>
                    </div>
                    <ul className={`${styles.ll_video_filter} ${styles.ll_no_scrollbar}`}>
                        { Object.values(videoFilterContext).length > 1
                            ?    ( 
                                    
                                    <li className={`${styles.ll_video_filter_item} ${ activeFilterLink === 'all' && styles.active_item}`}
                                        count={initialBookmarks.length}
                                        subjectcode="all"
                                        onClick={() => filterVideos('all')}>
                                            அனைத்து பாடங்களும்
                                    </li>
                                )
                            : <></>
                        }
                        {subjectFilters}
                    </ul>
                    <ul className={styles.ll_video_list}>
                        {videoItems}
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default VideoList;