import notificationsData from '../data/notifications.json'
import styles from '../styles/notificationsBar.module.scss'

const NotificationBar = (props) => {
    const toggleNotificationsBar = (event) => {
        event.preventDefault()

        const bodyNode = document.body;
        const headerNode = document.getElementById('ll_notification_instance');

        bodyNode.classList.toggle('notifications-overlay-active')
        headerNode.classList.toggle('active')
    };

    const notificationMessages = notificationsData.notifications.messages.map((message) => {
        return (
            <li className={`${styles.ll_notification_item} ${message.status === "new" ? styles.ll_new_item : ''} ${message.category === "ad" ? styles.ll_ad_item : ''}`}>
                <div className={styles.ll_message_wrap}>
                    <div className={styles.ll_message_img_wrap}>
                        <img className={styles.ll_message_img} src={message.thumbnail} alt={message.title}></img>
                    </div>
                    <div className={styles.ll_message_title_wrap}>
                        <h6 className={styles.ll_message_title}>{message.title}</h6>
                        <p className={`${styles.ll_message_desc} multi-line-ellipsis`}>{message.description}</p>
                    </div>
                </div>
            </li>
        );
    });

    return (
        <div id="ll_notification_instance" className={`${styles.ll_notifications} ll_notifications`}>
            <div className={styles.ll_title_wrap}>
                <h5 className={styles.ll_h3}>அறிவிப்புகள் <span className='text-llprimary'>({notificationsData.notifications.unreadLength})</span></h5>
                <button className="close-btn w-14 h-14 z-50 p-4 opacity-100" onClick={toggleNotificationsBar}>
                    <img className="w-full h-full" src="images/icons/close-icon-dark.png" alt="close overlay"></img>
                </button>  
            </div>
            <ul className={styles.ll_notifications_list}>
                {notificationMessages}
                <li className={`${styles.ll_notification_item}`}>
                    <p className={`${styles.ll_message_desc} ${styles.ll_default_item} multi-line-ellipsis`}>
                        Well, no more new messages.
                    </p>
                </li>
            </ul>
        </div>
    );
}

export default NotificationBar;