import Slider from "react-slick";

import theme from '../styles/theme.module.scss'
import styles from '../styles/adBannerHorizontalCards.module.scss'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const AdBannerHorizontalCards = (props) => {

    console.log('Rolling slides', props);

    const slideConfig = {
        autoplay: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 3000,
        arrows: false,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        cssEase: 'cubic-bezier(0.76, 0, 0.24, 1)',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
                {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
                {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
      ]
    };

    const promotedContentSlides = props.context.map((slide) => {
        return (
            <div className={styles.ll_promoted_slide}>
                <div className={styles.ll_slide_wrap}>
                    <a href={slide.href} className={styles.ll_hyperlink} target="new">
                        <span className="hidden">{slide.title}</span>
                    </a>
                    <img className={styles.ll_slide_img} src={slide.image} alt={slide.title}></img>
                </div>
            </div>
        );
    });

    return (
        <section className={`${styles.ll_horizontal_cards} ll_horizontal_cards ll_slick_dots`}>
            <div className={`${theme.ll_container} ${styles.ll_carousel_container}`}>
                <div className='flex w-full'>
                    {
                        props.title
                        ? <h3 className='text-lg lg:text-xl font-semibold text-left mb-4 px-4 lg:px-0'>{props.title}</h3>
                        : <></>
                    }
                </div>
                <Slider {...slideConfig} className={styles.ll_horizontal_slider}>
                    {promotedContentSlides}
                </Slider>
            </div>
        </section>
    );
}

export default AdBannerHorizontalCards;