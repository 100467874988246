import notificationsData from '../data/notifications.json'
import theme from '../styles/theme.module.scss'
import styles from '../styles/dataGrid.module.scss'

const DataGrid = (props) => {

    const itemIcon = (type) => {
        let image;

        switch(type) {
            case 'video':
                image = <img className={styles.ll_data_icon} src="/images/icons/video-icon.png" alt="video"></img>
                break;

            case 'quiz':
                image = <img className={styles.ll_data_icon} src="/images/icons/quiz-icon.png" alt="quiz"></img>
                break;

            case 'test':
                image = <img className={styles.ll_data_icon} src="/images/icons/exam-icon.png" alt="test"></img>
                break;

            default:
                image = <img className={styles.ll_data_icon} src="/images/icons/info-icon.png" alt="others"></img>
                break;
        }

        return image;
    };
   
    const dataRows = props.context.map((record) => {
        console.log(record);
        return (
            <li className={styles.ll_data_row} key={record.id}>
                <span className={styles.ll_data_image}>{itemIcon(record.type)}</span>
                <p className={styles.ll_data_title}>
                    <span className='hidden md:inline'>{record.subject} :: </span>
                    <span>{record.title}{record.title}</span>
                </p>
                <span className={styles.ll_data_timestamp}>{record.timestamp}</span>
            </li>
        );
    });

    return (
        <section className={`${styles.ll_data_grid} ll_data_grid`}>
            <div className={theme.ll_container}>
                <div className={styles.ll_title_wrap}>
                    <h3 className={styles.ll_h3}>{props.title}</h3>
                </div>
                <ul className={styles.ll_data_rows}>
                    {dataRows}
                </ul>
            </div>
        </section>
    );
}

export default DataGrid;